import axios from "axios";
import { useEffect, useState, Fragment, useMemo } from "react";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ProfileAccordions from "./ProfileAccordions";

const { REACT_APP_API_URL } = process.env;

export default function XCPals(props) {
    const { showNotification, user, theme } = props;
    
    const useStyles = makeStyles({
        button: {
        width: "80px",
        height: "25px",
        borderRadius: "12px",
        fontSize: "10px",
        margin: "5px",
        },
        root: {
        minHeight: 500,
        minWidth: 258,
        },
        bullet: {
        minHeight: "100px",
        margin: "0 2px",
        transform: "scale(0.8)",
        },
        title: {
        fontSize: 32,
        fontFamily: "GoodUnicorn",
        color: theme.primaryColor,
        },
        avatar: {
        marginLeft: "35%",
        marginTop: "10%",
        width: "20%",
        height: "20%",
        },
        palGrid: {
        minWidth: 300,
        },
        palrow: {
        margin: 10,
        },
        pos: {
        marginBottom: 12,
        },
        chip1: {
        width: 110,
        fontSize: 12,
        },
        chip2: {
        width: 120,
        fontSize: 12,
        },
        toggle: {
        margin: "10%",
        borderRadius: "15px",
        backgroundColor: "#fffefe",
        textAlign: "center",
        whiteSpace: "nowrap",
        },
        xcIcon: {
        margin: 10,
        },
        xcName: {
        fontSize: 12,
        margin: 5,
        },
        rewardCard: {
        backgroundColor: "#efeaff",
        },
        hobbyCard: {
        backgroundColor: "#dbf2d1",
        },
        videosCard: {
        backgroundColor: "#ffe5e5",
        },
        imagesCard: {
        backgroundColor: "#d6f1fe",
        },
        subCardReward: {
        width: "50%",
        minHeight: "40px",
        marginTop: "20%",
        marginLeft: "10%",
        backgroundColor: "#937cff",
        },
        subCardHobbies: {
        width: "50%",
        minHeight: "40px",
        marginTop: "20%",
        marginLeft: "10%",
        backgroundColor: "#4bbe0d",
        },
        subCardVideos: {
        width: "50%",
        minHeight: "40px",
        marginTop: "20%",
        marginLeft: "10%",
        backgroundColor: "#fe787f",
        },
        subCardImages: {
        width: "50%",
        minHeight: "40px",
        marginTop: "20%",
        marginLeft: "10%",
        backgroundColor: "#2cbcf8",
        },
    });
    
    const classes = useStyles();
    const [toggleFriends, setToggleFriends] = useState(null);
    const [selectedUser, setSelectedUser] = useState();
    const [friends, setFriends] = useState([]);
    const [pendingFriends, setPendingFriends] = useState([]);
    const [searchString, setSearchString] = useState("");
    
    
    const filterFriends = (e) => {
        setSearchString(e.target.value);
    };
    
    useEffect(async () => {
        if (toggleFriends == 0) {
            const result = await axios(`${REACT_APP_API_URL}/pending_friends/${user.id}`);
            setPendingFriends(result.data);
        }
    }, [toggleFriends]);
    
    useEffect(async () => {
        if (toggleFriends == 1) {
        const result = await axios(`${REACT_APP_API_URL}/friends/${user.id}`);
        setFriends(result.data);
        }
    }, [toggleFriends]);
    
    useEffect(() => {
        setToggleFriends(0);
    }, []);

    const removeFriend = async (userId) => {
        const result = await axios(
            `${REACT_APP_API_URL}/friends/${user.id}/${userId}`,
            { method: "delete" }
        );
        if (result.status === 200) {
            const index = pendingFriends.findIndex((item) => item.id === userId);
            pendingFriends.splice(index, 1);
            setPendingFriends(pendingFriends.splice(index, 1));
        }
    };
    
    const confirmFriend = async (userId) => {
        const result = await axios(
            `${REACT_APP_API_URL}/friends/approve/${user.id}/${userId}`,
            { method: "post" }
        );
        if (
            result.status === 200 &&
            result.data.success === "Friendship confirmed!"
        ) {
            const index = pendingFriends.findIndex((item) => item.id === userId);
            pendingFriends.splice(index, 1);
            setPendingFriends(pendingFriends.splice(index, 1));
            showNotification("Friendship confirmed!", "success");
        } else if (
            result.status === 200 &&
            result.data.success === "Awaiting other parent confirmation."
        ) {
            //put an alert here
            showNotification("Awaiting other parent confirmation.", "warning");
        }
    };
    
    const XCPalRow = useMemo(
        () => (props) => {
        const {
            pal,
            user,
            selectedUser,
            classes,
            confirmFriend,
            removeFriend,
            toggleFriends,
            style,
        } = props;
        return (
            <Paper
                elevation={0}
                style={
                    selectedUser && selectedUser.id === pal.id
                    ? { backgroundColor: "#f1f1f1", ...style }
                    : { ...style }
                }
                className={classes.palrow}
                onClick={() => setSelectedUser(pal)}
            >
                <Grid container spacing={2}>
                    <Grid xs={3}>
                        <BrAvatar user={pal} theme={theme} className={classes.xcIcon} />
                    </Grid>
                    <Grid xs={9}>
                    <Typography className={classes.xcName}> {pal.name} </Typography>
                    {toggleFriends === 0 ? (
                        <Fragment>
                            {user.isParent && (
                                <Button
                                    theme={theme}
                                    className={classes.button}
                                    onClick={() => {
                                        confirmFriend(pal.id);
                                    }}
                                    variant="contained"
                                    >
                                    {" "}
                                    Confirm{" "}
                                </Button>
                            )}
                            <Button
                                theme={theme}
                                className={classes.button}
                                onClick={() => {
                                removeFriend(pal.id);
                                }}
                                variant="contained"
                            >
                                {" "}
                                Delete{" "}
                            </Button>
                        </Fragment>
                    ) : (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={`/messenger/${pal.id}#` + pal.id}
                        >
                        <Button
                            theme={theme}
                            className={classes.button}
                            variant="contained"
                        >
                            {" "}
                            Chat{" "}
                        </Button>
                        </Link>
                    )}
                    </Grid>
                </Grid>
            </Paper>
        );
        },
        []
    );

    return (
        <div>
        <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid className={classes.title} item xs={11}>
            My XC Pals
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2} className={classes.palGrid}>
            <Card className={classes.root}>
                <Paper className={classes.toggle}>
                <Chip
                    onClick={() => setToggleFriends(1)}
                    className={classes.chip1}
                    style={
                    toggleFriends === 1
                        ? {
                            zIndex: 1,
                            color: theme.primaryColor,
                            backgroundColor: theme.secondaryColor,
                        }
                        : { backgroundColor: "#fffefe" }
                    }
                    label="My XC Pals"
                />
                <Chip
                    onClick={() => setToggleFriends(0)}
                    className={classes.chip2}
                    style={
                    toggleFriends === 0
                        ? {
                            zIndex: 1,
                            color: theme.primaryColor,
                            backgroundColor: theme.secondaryColor,
                        }
                        : { backgroundColor: "#fffefe" }
                    }
                    label="XC Pal Requests"
                />
                </Paper>
                <Paper
                elevation={5}
                style={{ width: "95%", textAlign: "center", marginLeft: "10px" }}
                >
                <TextField
                    style={{ width: "100%", height: "30px" }}
                    variant={"filled"}
                    placeholder={"Search"}
                    onChange={filterFriends}
                    value={searchString}
                    InputLabelProps={{
                    style: { height: "30px" },
                    shrink: true,
                    }}
                    InputProps={{
                    style: {
                        height: "35px",
                        backgroundColor: "white",
                        padding: 0,
                        after: {
                        content: "none",
                        },
                        before: { content: "none" },
                    },
                    shrink: true,
                    disableUnderline: true,
                    }}
                    inputProps={{
                    style: { paddingTop: "10px", paddingBottom: "0px" },
                    }}
                />
                </Paper>
                <div style={{ padding: "10px" }}></div>

                {toggleFriends
                ? friends.map((pal) => {
                    return searchString === "" ||
                        pal.name.toUpperCase().includes(searchString.toUpperCase()) ? (
                        <XCPalRow
                        user={user}
                        pal={pal}
                        selectedUser={selectedUser}
                        classes={classes}
                        confirmFriend={confirmFriend}
                        removeFriend={removeFriend}
                        toggleFriends={toggleFriends}
                        />
                    ) : (
                        <div
                        style={{ display: "none" }}
                        />
                    );
                    })
                : pendingFriends.map((pal) => {
                    return searchString === "" ||
                        pal.name.toUpperCase().includes(searchString.toUpperCase()) ? (
                        <XCPalRow
                        user={user}
                        pal={pal}
                        selectedUser={selectedUser}
                        classes={classes}
                        confirmFriend={confirmFriend}
                        removeFriend={removeFriend}
                        toggleFriends={toggleFriends}
                        />
                    ) : (
                        <XCPalRow
                        user={pal}
                        style={{ display: "none" }}
                        selectedUser={selectedUser}
                        classes={classes}
                        confirmFriend={confirmFriend}
                        removeFriend={removeFriend}
                        toggleFriends={toggleFriends}
                        />  
                    );
                    })}
            </Card>
            </Grid>
            <Grid item justify="center" xs={6} spacing={3}>
            <Card raised className={classes.root}>
                {selectedUser ? (
                <Fragment>
                    <div style={{ width: "100%", textAlign: "center" }}>
                    <BrAvatar
                        avatarStyles={{ width: "50%", height: "50%" }}
                        user={selectedUser}
                        theme={theme}
                        className={classes.avatar}
                    />
                    <Typography>{selectedUser.name}</Typography>
                    </div>
                    <ProfileAccordions
                    userId={selectedUser.id}
                    user={selectedUser}
                    />
                </Fragment>
                ) : (
                ""
                )}
            </Card>
            </Grid>
        </Grid>
        </div>
    );
}
