import { useState } from "react";
import { useHistory } from "react-router-dom";
import BrAvatar from "./Avatar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";




const Header = ({ theme, messageCount }) => {
    const useStyles = makeStyles((theme) => ({
        badge: {
            right: 10,
            top: 11,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px",
            justifyContent: "flex-end",
        },
        notifBadge: {
            right: 1,
            top: 15,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px",
            justifyContent: "flex-end",
        },
        root: {
            flexGrow: 1,
            justifyContent: "center",
        },
        topNav: {
            height: "75px",
            width: "100%",
        },
        control: {
            padding: theme.spacing(2),
        }
    }));
    
    const user = JSON.parse(localStorage.getItem("USER") || "null");
    const settings = JSON.parse(localStorage.getItem('SETTINGS') || 'null');
    const children = JSON.parse(localStorage.getItem('CHILDREN') || 'null');
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = useState(0);
    
    
    const SimpleMenu = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [notifs, setNotifCount] = useState(null);
        
        // useEffect(() => {
        //     async function fetchNotifications() {
        //         if (user && user.parent) {
        //             try {
        //                 const result = await axios.get(
        //                     `${REACT_APP_API_URL}/pending_friends/${user.id}`
        //                 );
        //                 setNotifCount(result.data.length);
        //                 window.Echo.private(`notifications.${user.parent.id}`).listen(
        //                     "SentNotification",
        //                     (e) => {
        //                         console.log(e);
        //                         setNotifCount(e.data.count);
        //                     }
        //                 );
        //             } catch (error) {
        //                 console.error("Error fetching notifications:", error);
        //             }
        //         }
        //     }
        //     fetchNotifications();
        // }, [user]);
        
        // useEffect(() => {
        //     if (user && user.id) {
        //         window.Echo.private(`notifs.${user.id}`).listen("SentNotif", (e) => {
        //             setNotifCount(prevState => (prevState ? [...prevState, e.notifs] : [e.notifs]));
        //         });
        //     }
        // }, [user]);
        
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        return (
                <Badge
                    badgeContent={notifs}
                    classes={{ badge: classes.badge }}
                    color="primary"
                >
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        style={{ color: theme.primaryColor }}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {user && children && children.length > 0 && (
                            <Badge
                                badgeContent={notifs}
                                classes={{ badge: classes.badge }}
                                color="primary"
                            >
                                <MenuItem onClick={() => setValue(2)}>
                                    Friend Requests
                                </MenuItem>
                            </Badge>
                        )}
                        <MenuItem
                            onClick={() => {
                                window.localStorage.removeItem("JWT_TOKEN");
                                window.localStorage.removeItem("USER");
                                window.localStorage.removeItem("CHILDREN");
                                window.localStorage.removeItem("SETTINGS");
                                window.location = `/`;
                            }}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
            </Badge>
        );
    };
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <img
                    style={{
                        marginLeft: "10px",
                        width: "256px",
                        height: "76px",
                    }}
                    src={user.gender === "F" ? "/icons/XcBrgPalGirls_sideways_02.png" : "/icons/XcBrgPalBoys_sideways_02.png"}
                    alt={user.gender === "F" ? "XcBrgPalGirls" : "XcBrgPalBoys"}
                />
            </Grid>
            
            <Grid item xs={1}>
                <Badge
                    badgeContent={messageCount}
                    color="primary"
                    style={{ justifyContent: "flex-end" }}
                >
                    {settings && settings?.chat_allowed === 1 ? (
                        <img
                            style={{
                                width: "24px",
                                height: "24px",
                                paddingLeft: "76px",
                                paddingTop: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push("/messenger")}
                            src="/icons/chat.png"
                            alt="Chat"
                        />
                    ) : null}
                </Badge>
            </Grid>
            <Grid
                item
                justifyContent="right"
                xs={1}
                spacing={3}
            >
                <BrAvatar
                    user={user}
                    theme={theme}
                    label={user.name}
                    onClick={() => history.push("/user/" + user.id)}
                    avatarStyles={{cursor: "pointer"}}
                />
            </Grid>
            <Grid item xs={1}>
                <SimpleMenu />
            </Grid>
        </Grid>
    );
};

export default Header;