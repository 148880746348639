import axios from "axios";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import Card from "@material-ui/core/Card";
import { TextField, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;


export default function ParentLogin(props) {
    const history = useHistory();
    const { register: registerParent, handleSubmit: handleSubmitParent } = useForm();
    // const { register, handleSubmit } = useForm();
    const [sentEmail, setSentEmail] = useState(false);
    
    const onSubmitParent = async (data) => {
        console.log(data);
        const { email, code } = data;
        if (!sentEmail) {
            try {
                await axios.post(`${REACT_APP_API_URL}/auth/send-login-code`, { email });
                setSentEmail(true);
            } catch (e) {
                console.error("🚀 ~ file: UnifiedLogin.jsx ~ onSubmitParent ~ e:", e);
                setSentEmail(true); // Set to true even on error to allow retry
            }
        } else if (code) {
            try {
                const result = await axios.post(`${REACT_APP_API_URL}/auth/login-parent`, data);
                window.window.localStorage.setItem("JWT_TOKEN", result.data.access_token);
                window.window.localStorage.setItem("USER", JSON.stringify(result.data));
                window.window.localStorage.setItem("SETTINGS", JSON.stringify(result.data.settings));
                window.window.localStorage.setItem("CHILDREN", JSON.stringify(result.data.children));
                // setUser(result.data);
                // history.push("/dashboard/home");
                window.location.reload();
            } catch (e) {
                console.error("🚀 ~ file: UnifiedLogin.jsx ~ onSubmitParent ~ e:", e);
            }
        }
    };
    
    // const onSubmit = async (data) => {
    //     const { email, code } = data;
    //     if (!sentEmail) {
    //         try {
    //             await axios.post(`${REACT_APP_API_URL}/auth/send-login-code`, { email });
    //             setSentEmail(true);
    //         } catch (e) {
    //             setSentEmail(true);
    //         }
    //     }
    //     if (code) {
    //         try {
    //             const result = await axios.post(`${REACT_APP_API_URL}/auth/login-parent`, data);
    //             console.log("🚀 ~ file: ParentLogin.jsx:32 ~ onSubmit ~ result:", result.data);
    //             window.window.localStorage.setItem("JWT_TOKEN", result.data.access_token);
    //             window.window.localStorage.setItem("USER", JSON.stringify(result.data));
    //             window.window.localStorage.setItem("SETTINGS", JSON.stringify(result.data.settings));
    //             window.window.localStorage.setItem("CHILDREN", JSON.stringify(result.data.children));
                
    //             // Log each of the value in localStorage
    //             for (let i = 0; i < localStorage.length; i++) {
    //                 console.log("🚀 ~ file: ParentLogin.jsx:44 ~ onSubmit ~ localStorage Data:", i, localStorage.key(i), localStorage.getItem(localStorage.key(i)));
    //             };
                
    //             // TODO: See if history.push is needed?
    //             // history.push("/dashboard/parents");
    //             window.location.reload();
    //         } catch (e) {}
    //     }
    // };
    
    const useStyles = makeStyles((theme) => ({
        brand: {
            width: "100%",
            height: "100%",
            margin: "24px",
        },
        brandGrid: {
            margin: "auto",
            textAlign: "center",
        },
        button: {
            backgroundColor: '#b4fa64',
            color: '#000000',
            '&:hover': {
                backgroundColor: '#a3e957',
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        hiddenInput: {
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
        },
        root: {
            width: "100%",
            height: "100%",
            marginTop: "-5%",
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            "& .MuiTypography-root": {
                fontSize: 30,
                fontFamily: "NeueHassDisplayMedium",
            },
            color: "#B4FA64", //'#eb4c8a'
            textAlign: "center",
            padding: 24,
        },
        margin: {
            margin: theme.spacing(1),
        },
        modal: {
            width: "40%",
            margin: "auto",
            padding: "24px",
            backgroundColor: "#FF6419",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            zIndex: 1,
            borderRadius: 12,
            "& .MuiOutlinedInput-root": {
                background: "white",
                fontFamily: "NeueHassDisplayMedium",
                borderRadius: 16,
            },
        },
        divider: {
            margin: 10,
        },
        grid: {
            padding: 5,
        },
        
        rememberMe: {
            paddingTop: 20,
            "& .MuiTypography-root": {
                color: "#B4FA64",
            },
            "& .MuiCheckbox-root": {
                color: "#B4FA64",
            },
        },
        submitButtonGrid: {
            textAlign: "center",
        },
        submitButton: {
            width: "80%",
            textTransform: "capitalize",
        },
        loginCard: {
            backgroundColor: '#ff6419',
            borderRadius: theme.spacing(2),
            padding: theme.spacing(7),
            margin: theme.spacing(0),
            width: '325px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        logo: {
            width: '200px',
            marginBottom: theme.spacing(1),
        },
        loginTitle: {
            color: '#b4fa64',
            marginBottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        input: {
            marginBottom: theme.spacing(2),
            '& .MuiOutlinedInput-root': {
                backgroundColor: '#ffffff',
            },
        }
    }));
    
    const buttonTheme = {
        primaryColor: '#b4fa64',
        secondaryColor: '#000000',
        fontSize: '16px',
        borderRadius: '8px',
        fontFamily: 'NeueHassDisplayMedium'
    };
    
    const classes = useStyles();
    
    // const modalBody = (
    //     <Grid container>
    //         <Grid className={classes.brandGrid} item xs={5}>
    //             <img
    //                 src="/images/Login_Logo_kids.svg"
    //                 style={{ width: "100%", marginTop: "10%" }}
    //                 alt=""
    //             ></img>
    //         </Grid>
            
    //         <Grid className={classes.modal} item xs={4}>
    //             <form id="login" onSubmit={handleSubmit(onSubmit)}>
    //                 <Grid container>
    //                     <Grid className={classes.title} item xs={12}>
    //                         <Typography variant="h5">Parent Login</Typography>
    //                     </Grid>
    //                     <Grid className={classes.grid} item xs={12}>
    //                         <TextField
    //                             placeholder="Email Address"
    //                             variant="outlined"
    //                             style={sentEmail ? { display: "none" } : {}}
    //                             fullWidth
    //                             {...register("email")}
    //                             sx={{
    //                                 secondaryColor: "#bc40fb",
    //                                 primaryColor: "#E040FB",
    //                             }}
    //                         />
    //                         <TextField
    //                             placeholder="Code"
    //                             variant="outlined"
    //                             style={!sentEmail ? { display: "none" } : {}}
    //                             fullWidth
    //                             {...register("code")}
    //                             sx={{
    //                                 secondaryColor: "#bc40fb",
    //                                 primaryColor: "#E040FB",
    //                             }}
    //                         />
    //                     </Grid>
    //                     <Grid className={classes.submitButtonGrid} item xs={12}>
    //                         <Button
    //                             className={classes.submitButton}
    //                             type="submit"
    //                             form="registration-form"
    //                             onClick={handleSubmit(onSubmit)}
    //                             theme={{
    //                                 secondaryColor: "#FF6419",
    //                                 primaryColor: "#B4FA64",
    //                                 fontSize: "24px",
    //                                 borderRadius: "24px",
    //                                 fontFamily: "NeueHassDisplayRoman",
    //                             }}
    //                         >
    //                             {" "}
    //                             {sentEmail ? "Login" : "Send Code"}
    //                         </Button>
    //                     </Grid>
    //                 </Grid>
    //             </form>
    //         </Grid>
    //     </Grid>
    // );
    
    return (
        <>
            {/* <div style={{ height: "100vh", backgroundSize: "cover" }}>
                <Card
                    open={true}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {modalBody}
                </Card>
            </div>
            <div className={classes.root}></div>
            <footer
                style={{
                    height: "70px",
                    backgroundColor: "#444",
                    marginTop: "-70px",
                    padding: "20px",
                }}
            >
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://bridgepalkids.com"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            BridgePal Website{" "}
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://bridgepalkids.com/terms"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            BridgePal Terms of Use
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://bridgepalkids.com/privacy-policy"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            BridgePal Privacy Policy
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://bridgepalkids.com/kids-privacy-policy"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            BridgePal Kids Privacy{" "}
                        </a>{" "}
                    </Grid>
                </Grid>
            </footer> */}
            
            <div className={classes.loginCard}>
                    <Typography variant="h3" className={classes.loginTitle}>Parent's Login</Typography>
                    <form id="parent-login-form" onSubmit={handleSubmitParent(onSubmitParent)}>
                        <TextField
                            className={classes.input}
                            placeholder="Email Address"
                            variant="outlined"
                            fullWidth
                            style={sentEmail ? { display: "none" } : {}}
                            {...registerParent("email")}
                        />
                        <TextField
                            className={classes.input}
                            placeholder="Code"
                            variant="outlined"
                            fullWidth
                            style={!sentEmail ? { display: "none" } : {}}
                            {...registerParent("code")}
                        />
                        <Button
                            type="submit"
                            form="parent-login-form"
                            className={classes.button}
                            theme={buttonTheme}
                            onClick={handleSubmitParent(onSubmitParent)}
                        >
                            {sentEmail ? "Login" : "Send Code"}
                        </Button>
                    </form>
                </div> 
        </>
    );
};