import { makeStyles } from "@material-ui/core/styles";
import { Button as MButton } from "@material-ui/core";

export default function Button(props) {
  const { theme, style, className } = props;
  
  const useStyles = makeStyles((theme) => ({
    button: {
      borderRadius: theme.borderRadius ?? "16px",
      fontSize: theme.fontSize ?? "12px",
      fontFamily: theme.fontFamily ?? "NeueHassDisplayMedium",
      background: theme ? theme.primaryColor : "#bbb",
      color: theme ? theme.secondaryColor : "black"      
    },
  }));
  const classes = useStyles();

  return (
    <MButton
      component="span"
      onClick={props.onClick}
      className={`${classes.button} ${className}`}
      style={style}
      variant="contained"
    >
      {" "}
      {props.children}{" "}
    </MButton>
  );
}
