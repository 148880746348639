import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { Dashboard } from "./Dashboard";
import Hobbies from "./Profile/Hobbies";
import MessengerPage from "./Profile/MessengerPage";
import UnifiedLogin from "./Modals/UnifiedLogin";
import SelectChildUser from "./Modals/SelectChildUser";
import Profile from "./Profile/Profile";
import Logout from "./Logout";


const PrivateRoute = ({ children, user, ...rest }) => {
const USER = localStorage.getItem("USER") ? JSON.parse(localStorage.getItem("USER")) : user;
    return (
        <Route
            {...rest}
            render={({ location }) =>
                USER ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

export default function Routes(props) {
    // const kids = localStorage.getItem('CHILDREN') ? localStorage.getItem('CHILDREN') : null;
    const {
        showNotification,
        user,
        setUser,
        theme,
        kids,
        proceedWithChild,
        selectChild,
        isLoading,
        setIsLoading
    } = props;
        console.log("🚀 ~ file: Routes.jsx:49 ~ Routes ~ user:", user);
        console.log("🚀 ~ file: Routes.jsx:49 ~ Routes ~ kids:", kids);
    
    // Comment in for debugging:
    // console.log("🚀 ~ file: Routes.jsx:50 ~ Routes ~ user:", user);
    // console.log("🚀 ~ file: Routes.jsx:51 ~ Routes ~ kids:", kids);
    // console.log("🚀 ~ file: Routes.jsx:52 ~ Routes ~ isDataFetched:", isDataFetched);
    console.log("🚀 ~ file: Routes.jsx:56 ~ Routes ~ selectChild:", selectChild);
    
    // If user is not authenticated, only render public routes
    if (!user && !isLoading) {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <UnifiedLogin user={user} setUser={setUser} />
                    </Route>
                </Switch>
            </Router>
        );
    }
    
    if (selectChild && user.children?.length > 0) {
        console.log("🚀 ~ file: Routes.jsx:70 ~ Routes ~ children?.length:", kids?.length);
        return (
            <Router>
                <SelectChildUser
                    open
                    user={user}
                    kids={kids}
                    proceedWithChild={proceedWithChild}
                />
            </Router>
        );
    }
    
    return (
        <Router>
            <Switch>
                <PrivateRoute user={user} exact path="/">
                    <Redirect to="/dashboard/home" />
                </PrivateRoute>
                <PrivateRoute
                    user={user}
                    path="/select-kid"
                    kids={kids}
                    proceedWithChild={proceedWithChild}
                >
                    <SelectChildUser
                        open
                        user={user}
                        kids={kids}
                        proceedWithChild={proceedWithChild}
                    />
                </PrivateRoute>
                <PrivateRoute user={user} path="/dashboard">
                    <Dashboard
                        user={JSON.parse(localStorage.getItem('USER') || user)}
                        showNotification={showNotification}
                        theme={theme}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    >
                        <Switch>
                            <Route path="/dashboard/home" />
                            <Route path="/dashboard/life_topics" />
                            <Route path="/dashboard/xc_pals" />
                            <Route path="/dashboard/journal" />
                            <Route path="/dashboard/resources" />
                            <Route path="/dashboard/parents" />
                            <Redirect from="/dashboard" to="/dashboard/home" />
                        </Switch>
                    </Dashboard>
                </PrivateRoute>
                <PrivateRoute user={user} path="/hobbies">
                    <Hobbies
                        user={user}
                        theme={theme}
                        showNotification={showNotification}
                    />
                </PrivateRoute>
                <PrivateRoute user={user} path="/messenger">
                    <MessengerPage
                        user={user}
                        theme={theme}
                        showNotification={showNotification}
                    />
                </PrivateRoute>
                <PrivateRoute user={user} path="/user/:userId">
                    <Profile
                        user={user}
                        theme={theme}
                        showNotification={showNotification}
                    />
                </PrivateRoute>
                <Route path="/logout">
                    <Logout />
                </Route>
                <Redirect to="/dashboard" />
            </Switch>
        </Router>
    );
};