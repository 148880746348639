import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";


export default function Messenger(props) {
    const {
        characterCount,
        messages,
        message,
        selectedUser,
        sendMessage,
        handleMessageInput,
        handleRemove,
        handleEdit,
        edit,
        user,
        theme,
    } = props;
    console.log("🚀 ~ file: Messenger.jsx:29 ~ Messenger ~ selectedUser:", selectedUser);
    
    const useStyles = makeStyles({
    root: {
        minHeight: 500,
        maxHeight: 500,
    },
    bullet: {
        minHeight: "100px",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    conversation: {
        width: "100%",
        overflow: "scroll",
        height: "332px",
        paddingInlineStart: 0,
    },
    title: {
        fontSize: 32,
        fontFamily: "GoodUnicorn",
        color: "#eb4c8a",
    },
    avatar: {
        marginLeft: "30%",
        marginTop: "40%",
        width: "20%",
        height: "20%",
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        fontSize: 16,
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    chatGrid: {
        marginLeft: "40px",
    },
    playIcon: {
        color: "#eb4c8a",
        cursor: "pointer",
        position: "absolute",
        top: "20%",
        left: "25%",
        transform: "translate(-25%, -25%)",
    },
    imagesCard: {
        backgroundColor: "#d6f1fe",
    },
    images: {
        margin: "4px",
    },
    toggle: {
        margin: "10%",
        borderRadius: "15px",
        backgroundColor: "#fffefe",
        textAlign: "center",
    },
    self: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    selfChip: {
        background: `linear-gradient(90deg, ${theme.secondaryColor} 20%, ${theme.primaryColor} 80%)`,
        color: "white",
    },
    editChip: {
        background: `${theme.primaryColor}`,
        color: "white",
    },
    recipientChip: {
        background: "floralwhite",
    },
    });
    
    const classes = useStyles();
    
    const chipCustom = makeStyles({
        label: { whiteSpace: "normal", overflow: "auto", margin: "5px" },
    })();
    
    const SimpleMenu = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const { message } = props;
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        return (
            <Badge
                classes={{ badge: classes.badge }}
                color="primary">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => handleEdit(message)}
                    >
                    Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleRemove(message)}
                    >
                    Delete
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    console.log("🚀 ~ file: Messenger.jsx:167 ~ Messenger ~ selectedUser:", selectedUser);
    return (
        <Paper
            elevation={1}
            className={classes.root}
            style={{ minWidth: 700, position: "absolute" }}
        >
            <div
            style={{
                textAlign: "center",
                height: "45px",
                backgroundColor: "rgb(241,241,241)",
            }}
            >
                <div style={{ padding: "10px", margin: "auto" }}>
                    {" "}
                    Chat with {selectedUser.name}{" "}
                </div>
            </div>
            
            <Grid container>
                <ol className={classes.conversation}>
                    {messages.map((message) => {
                        let chipClass ='';
                        if(message.sender_id !== user.id) {
                            chipClass = classes.recipientChip;
                        } else if(edit.id === message.id ){
                            chipClass = classes.editChip
                        } else {
                            chipClass = classes.selfChip;
                        }
                        
                        return (
                            <li
                            style={{ display: "flex" }}
                            className={message.sender_id === user.id ? classes.self : ""}
                            >
                            <img class="avatar" />
                            
                            <div class="message">
                                <div style={{marginLeft:'0px'}}>{new Date(message.created_at).toLocaleDateString()}</div>
                                    <Chip
                                    className={chipClass}
                                    style={{
                                        margin: "5px",
                                        maxWidth: "250px",
                                        maxHeight: "150px",
                                        minHeight: "32px",
                                        height: "auto",
                                        paddingBottom: "20px",
                                    }}
                                    label={message.content}
                                    classes={{ label: chipCustom.label }}
                                    >
                                    {" "}
                                    </Chip>
                                    {message.sender_id === user.id ? <SimpleMenu message={message} /> : null }
                                </div>
                            </li>
                        );
                    })}
                </ol>
            </Grid>
            {user.is_kid && (
                <React.Fragment>
                    <TextField
                    style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "95%",
                    }}
                    multiline
                    value={message}
                    onChange={(e) => handleMessageInput(e)}
                    onKeyDown={(e) => handleMessageInput(e)}
                    placeholder="Write your message here..."
                    variant="filled"
                    ></TextField>
                    <Typography> {characterCount}/240</Typography>
                    <IconButton
                    style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                        maxHeight: "32px",
                        maxWidth: "32px",
                    }}
                    onClick={sendMessage}
                    >
                    <img
                        style={{
                        maxHeight: "24px",
                        maxWidth: "24px",
                        marginBottom: "5px",
                        }}
                        src="/icons/001-paper-plane.png"
                    />
                    </IconButton>
                </React.Fragment>
            )}
        </Paper>
    );
};