import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useHistory} from "react-router-dom";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Input/Button";
import ProfileAccordions from "./ProfileAccordions";
import SelectAvatarModal from "../Modals/SelectAvatarModal";
import SelectNationalityModal from "../Modals/SelectNationalityModal";
import CountriesMap from "../Profile/CountriesMap";

const { REACT_APP_API_URL } = process.env;

export default function Profile(props) {
    const { user, showNotification, theme } = props;
    console.log("🚀 ~ file: Profile.jsx:19 ~ Profile ~ user:", user);
    const { userId } = useParams();
    console.log("🚀 ~ file: Profile.jsx:20 ~ Profile ~ userId:", userId);
    const history = useHistory();
    
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 500,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
        },
        avatar: {
            marginLeft: "35%",
            marginTop: "40%",
            width: "20%",
            height: "20%",
        },
        pos: {
            marginBottom: 12,
        },
        rewardCard: {
            backgroundColor: "#efeaff",
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        hobbyCard: {
            backgroundColor: "#dbf2d1",
        },
        videosCard: {
            backgroundColor: "#ffe5e5",
        },
        videoCardContainer: {
            margin: "4px",
            width: "80%",
            height: "75%",
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)",
        },
        imagesCard: {
            backgroundColor: "#d6f1fe",
        },
        images: {
            margin: "4px",
        },
        subCardReward: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#937cff",
        },
        subCardHobbies: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#4bbe0d",
        },
        hobbyIcon: {
            width: "80%",
            minHeight: "40px",
            marginTop: "20%",
            backgroundColor: "#dbf2d1",
        },
        hobbyLink: {
            marginBottom: "-10px",
            color: "#4bbe0d",
        },
        subCardVideos: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#fe787f",
        },
        subCardImages: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#2cbcf8",
        },
        xcReq: {
            marginLeft: "35%",
            width: "20%",
            height: "20%",
        },
    });
    
    const classes = useStyles();
    
    const [profileData, setProfileData] = useState({
        profileUser: {},
        myFriends: [],
        myPendingFriends: [],
        userHobbies: [],
        userImages: [],
        userVideos: [],
        awardCount: 0
    });
    
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [showNationalityModal, setShowNationalityModal] = useState(false);
    
    const fetchProfileData = useCallback(async () => {
        try {
            const [userProfileResult, friendsResult, pendingFriendsResult, hobbiesResult, imagesResult, videosResult] = await Promise.all([
                axios(`${REACT_APP_API_URL}/user/${userId}`),
                axios(`${REACT_APP_API_URL}/friends/${userId}`),
                axios(`${REACT_APP_API_URL}/pending_friends/${userId}`),
                axios(`${REACT_APP_API_URL}/hobbies/${userId}`),
                axios(`${REACT_APP_API_URL}/uploads/${userId}?type=image`),
                axios(`${REACT_APP_API_URL}/uploads/${userId}?type=video`),
            ]);
            console.log("🚀 ~ file: Profile.jsx:150 ~ fetchProfileData ~ userProfileResult:", userProfileResult);
            
            const awardCount = userProfileResult.data.posts
                ? userProfileResult.data.posts.reduce((prev, current) => prev + current.awards.length, 0)
                : 0;
            
            setProfileData({
                profileUser: userProfileResult.data,
                myFriends: friendsResult.data,
                myPendingFriends: pendingFriendsResult.data,
                userHobbies: hobbiesResult.data,
                userImages: imagesResult.data,
                userVideos: videosResult.data,
                awardCount
            });
        } catch (error) {
            console.error("Error fetching profile data:", error);
            showNotification("Failed to load profile data", "error");
        }
    }, [userId, showNotification]);
    
    useEffect(() => {
        fetchProfileData();
    }, [fetchProfileData]);

    const requestFriend = async () => {
        try {
            const result = await axios.post(`${REACT_APP_API_URL}/friends/${userId}`);
            if (result.status === 200) {
                showNotification("Friend requested, awaiting parental approval", "success");
                fetchProfileData();
            }
        } catch (error) {
            showNotification("Failed to send friend request", "error");
        }
    };
    
    const cancelRequest = async () => {
        try {
            await axios.delete(`${REACT_APP_API_URL}/friends/${userId}/${userId}`);
            fetchProfileData();
        } catch (error) {
            showNotification("Failed to cancel friend request", "error");
        }
    };
    
    const FriendActions = () => {
        const { profileUser, myFriends, myPendingFriends } = profileData;
        
        if (!profileUser.id || user.isParent || user.is_mentor || (user?.settings && user?.settings?.profile_hidden === 1)) {
            return null;
        }
        
        if (profileUser.settings && profileUser.settings.profile_hidden === 1) {
            return <div style={{ textAlign: "center", width: "100%" }}>This user does not accept friend requests.</div>;
        }
        
        const isFriend = myFriends.some(f => f.id === profileUser.id);
        const isPendingFriend = myPendingFriends.some(f => f.id === profileUser.id);
        
        if (isFriend) {
            return (
                <Link style={{ textDecoration: "none" }} to={`/messenger/${userId}/${userId}`}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Button theme={theme} className={classes.xcReq}>Chat</Button>
                    </div>
                </Link>
            );
        }
        
        if (isPendingFriend) {
            return (
                <div style={{ textAlign: "center", width: "100%" }}>
                    <Button theme={theme} onClick={cancelRequest} className={classes.xcReq}>Cancel Request</Button>
                </div>
            );
        }
        
        return (
            <div style={{ textAlign: "center", width: "100%" }}>
                <Button theme={theme} onClick={requestFriend} className={classes.xcReq}>Request Friend</Button>
            </div>
        );
    };
    
    const calculateAge = (dob) => {
        const ageDifMs = Date.now() - new Date(dob).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    
    const { profileUser, awardCount } = profileData;
    console.log("🚀 ~ file: Profile.jsx:242 ~ Profile ~ profileUser:", profileUser);
    
    const navigateToDashboard = () => {
        history.push("/dashboard/home");
    };
    
    return (
        <div>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid className={classes.title} item xs={11}>
                        Profile
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Card raised className={classes.root}>
                            <div style={{ textAlign: "center", margin: "5px", fontWeight: "bold" }}>
                                {profileUser.name}
                            </div>
                            
                            {profileUser.dob && (
                                <div style={{ textAlign: "center", margin: "5px", fontSize: "14px" }}>
                                    {calculateAge(profileUser.dob) + " Years Old"}
                                </div>
                            )}
                            
                            {profileUser.avatar && (
                                <BrAvatar
                                    avatarStyles={{ width: "50%", height: "50%" }}
                                    user={profileUser}
                                    theme={theme}
                                    className={classes.avatar}
                                />
                            )}
                            {(profileUser.id === userId && user.is_kid) && (
                                <div style={{ textAlign: "center", width: "100%", marginTop: 5 }}>
                                    <Button
                                        theme={theme}
                                        onClick={() => setShowAvatarModal(true)}
                                        className={classes.xcReq}
                                    >
                                        Change Avatar
                                    </Button>
                                </div>
                            )}
                            
                            
                            {profileUser.id !== userId && (
                                <FriendActions />
                            )}
                            
                            <div style={{ textAlign: "center", width: "100%", marginTop: 5 }}>
                                {profileUser.id === userId && user.is_kid && (
                                    <div style={{ textAlign: "center", width: "100%", marginTop: 5, marginBottom: 5 }}>
                                        <Button
                                            theme={theme}
                                            onClick={() => setShowNationalityModal(true)}
                                            className={classes.xcReq}
                                        >
                                            Nationality
                                        </Button>
                                    </div>
                                )}
                                
                                {profileUser && (
                                    <>
                                        <img
                                            src={`https://flagcdn.com/16x12/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png`}
                                            srcSet={`https://flagcdn.com/32x24/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png 2x,
                                                https://flagcdn.com/48x36/${profileUser?.nationality?.toLowerCase() ?? 'us'}.png 3x`}
                                            width="16"
                                            height="12"
                                            alt={`Flag of ${profileUser?.nationality ?? 'US'}`}
                                        />
                                        {" "}
                                        {CountriesMap[profileUser?.nationality?.toUpperCase() ?? 'US']}
                                    </>
                                )}
                            </div>
                        </Card>
                    </Grid>
                    
                    <Grid item justifyContent="center" xs={6} spacing={3}>
                        <Card raised className={classes.root}>
                            <ProfileAccordions
                                userId={userId}
                                user={profileUser}
                                awardCount={awardCount}
                            />
                        </Card>
                    </Grid>
                </Grid>
                <SelectAvatarModal
                    user={profileUser}
                    theme={theme}
                    open={showAvatarModal}
                    handleClose={() => setShowAvatarModal(false)}
                />
                <SelectNationalityModal
                    user={profileUser}
                    theme={theme}
                    open={showNationalityModal}
                    handleClose={() => setShowNationalityModal(false)}
                />
            </div>
            
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                    onClick={navigateToDashboard}
                    theme={theme}
                    style={{ 
                        fontWeight: 'bold',
                        padding: '10px 20px',
                        fontSize: '16px'
                    }}
                >
                    Dashboard
                </Button>
            </div>
        </div>
    );
};