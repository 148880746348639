import axios from "axios";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import WarningBeforeLeavingWebsiteButtonAndModal from "../Modals/WarningBeforeLeavingWebsiteButtonAndModal";
import Loading from "../Loading";


const { REACT_APP_API_URL } = process.env;


export default function OnlineResources(props) {
    const { theme, user } = props;
    console.log("🚀 ~ file: OnlineResources.jsx:14 ~ OnlineResources ~ user:", user);
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 500
        },
        lifeTopicCard: {
            minWidth: 250,
            minHeight: 150,
            textAlign: "center",
            marginLeft: "15px",
            marginTop: "15px",
            backgroundColor: "#e8f3ff",
            height: 300
        },
        media: {
            height: 64,
            width: 128,
            margin: "auto",
            backgroundSize: "contain"
        },
        input: {
            height: "500px",
            resize: "none"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
            marginTop: "15px",
        }
    });
    
    const classes = useStyles({ theme });
    
    const [isLoading, setIsLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [open, setOpen] = useState(false);
    const [onlineResourcesAllowed, setOnlineResourcesAllowed] = useState(false);
    
    
    useEffect(() => {
        const checkPermissionAndFetchResources = async () => {
            if (user?.settings?.online_resources === 1) {
                setOnlineResourcesAllowed(true);
                try {
                    const result = await axios(`${REACT_APP_API_URL}/resources`);
                    setResources(result.data);
                    setIsLoading(false);
                } catch (error) {
                    console.error("Error fetching resources:", error);
                }
            } else {
                console.log("Online resources not enabled for this user.");
                setOnlineResourcesAllowed(false);
            }
        };
        
        checkPermissionAndFetchResources();
    }, [user]);
    
    const handleClose = () => {
        setOpen(false);
    };
    
    if (!onlineResourcesAllowed) {
        return null;
    };
    
    if (isLoading) {
        return Loading();
    }
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid className={classes.title} item xs={11}>
                XC Xplor
            </Grid>
            <Grid item xs={3} />
            
            <Grid container justifyContent="center" xs={12} spacing={3}>
                {resources.map((resource) => (
                    <Grid item xs={3} key={resource.id}>
                        <Card className={classes.lifeTopicCard}>
                            <CardMedia
                                className={classes.media}
                                image={resource.image_url}
                            />
                            <Typography style={{padding: 15}}>{resource.description}</Typography>
                            <div style={{ margin: "10px" }}>
                                <WarningBeforeLeavingWebsiteButtonAndModal
                                    hideBackdrop
                                    open={open}
                                    theme={theme}
                                    user={user}
                                    onClose={handleClose}
                                    location={resource.url}
                                    buttonText="Visit Site"
                                />
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};