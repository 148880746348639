import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
    return (
        // <div className="loading-container">
        //     <div className="spinner"></div>
        //     <p className="loading-text">Loading...</p>
        //         <style jsx>{`
        //             .loading-container {
        //                 display: flex;
        //                 flex-direction: column;
        //                 justify-content: center;
        //                 align-items: center;
        //                 height: 100vh;
        //                 background-color: #f0f0f0;
        //             }
        //             .spinner {
        //                 width: 50px;
        //                 height: 50px;
        //                 border: 5px solid #f3f3f3;
        //                 border-top: 5px solid #3498db;
        //                 border-radius: 50%;
        //                 animation: spin 1s linear infinite;
        //             }
        //             .loading-text {
        //                 margin-top: 16px;
        //                 font-size: 1.25rem;
        //                 font-weight: 600;
        //             }
        //             @keyframes spin {
        //                 0% { transform: rotate(0deg); }
        //                 100% { transform: rotate(360deg); }
        //             }
        //         `}
        //         </style>
        // </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
    );
};


export default Loading;