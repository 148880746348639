import axios from "axios";
import Echo from "laravel-echo";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const {
    REACT_APP_API_URL,
    REACT_APP_PRIVO_HUB_URL,
    REACT_APP_PRIVO_CLIENT_ID,
    REACT_APP_PRIVO_REDIRECT_URL,
} = process.env;

function Logout() {
    const history = useHistory();

    useEffect(async () => {
        let token = window.localStorage.getItem("JWT_TOKEN");

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        console.log(token);

        const result = await axios(
            `${REACT_APP_API_URL}/auth/logout` + (window.location.search || "")
        ).then((result) => {
            window.window.localStorage.setItem("JWT_TOKEN", undefined);
            history.push(`https://${REACT_APP_API_URL}/login`);
        });
    }, []);

    return <div />;
}

export default Logout;
