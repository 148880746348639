import axios from "axios";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import TermsAndConditionsModal from "../Modals/TermsAndConditionsModal";
import TimeManagementModal from "../Modals/TimeManagementModal";
import PaymentSignUp from "../Modals/PaymentSignUp";
import Button from "../Input/Button";

const { REACT_APP_API_URL } = process.env;


export default function ParentsPortal(props) {
    const settings = JSON.parse(localStorage.getItem('SETTINGS') || 'null');
    const { user, theme } = props;
    console.log("🚀 ~ file: ParentsPortal.jsx:24 ~ ParentsPortal ~ user:", user);
    
    const useStyles = makeStyles({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px"
        },
        root: {
            minHeight: 500,
            minWidth: 258
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor
        },
        modal: {
            position: "absolute",
            width: 500,
            border: "2px solid #000",
            top: "30%",
            left: "30%"
        },
        
        pos: {
            marginBottom: 12
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#bbb", //'#fffefe',
            textAlign: "center"
        },
        
        divider: {
            margin: 10
        }
    });
    
    const classes = useStyles();
    const [state, setState] = useState({
        onlineResources: settings ? settings?.online_resources === 1 : false,
        chat: settings ? settings?.chat_allowed === 1 : false,
        hiddenProfile: settings ? settings?.profile_hidden === 1 : false
    });
    
    console.log("🚀 ~ file: ParentsPortal.jsx:76 ~ ParentsPortal ~ state:", {state});
    // const handleChange = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.checked });
    // };
    
    const handleOnlineResources = async (newValue) => {
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
            method: "POST",
            data: { online_resources: newValue }
        });
            setState({ ...state, onlineResources: newValue });
    };
    
    const handleChatPermission = async (newValue) => {
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
            method: "POST",
            data: { chat_allowed: newValue }
        });
            setState({ ...state, chat: newValue });
    };
    
    const handleHideProfile = async (newValue) => {
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
            method: "POST",
            data: { profile_hidden: newValue }
        });
        setState({ ...state, hidden: newValue });
    };
    
    const [openPI, setOpenPI] = useState(false);
    
    const handleOpenPI = () => {
        setOpenPI(true);
    };
    
    const handleClosePI = () => {
        setOpenPI(false);
    };
    
    const [openTM, setOpenTM] = useState(false);
    
    const handleOpenTM = () => {
        setOpenTM(true);
    };
    
    const handleCloseTM = () => {
        setOpenTM(false);
    };
    
    const [openTC, setOpenTC] = useState(false);

    const handleOpenTC = () => {
        setOpenTC(true);
    };
    
    const handleCloseTC = () => {
        setOpenTC(false);
    };
    
    const [openSubscribe, setOpenSubscribe] = useState(false);
    
    const handleOpenSubscribe = () => {
        setOpenSubscribe(true);
    };
    const handleCloseSubscribe = () => {
        setOpenSubscribe(false);
    };
    
    const GreySwitch = withStyles({
        switchBase: {
            color: theme.primaryColor,
            "&$checked": {
                color: theme.primaryColor,
            },
            "&$checked + $track": {
                backgroundColor: theme.primaryColor,
            },
        },
        checked: {},
        track: {}
    })(Switch);
    
    if (!user.name === "parent") {
        return null;
    };
    
    return (
        <div>
            <Grid container spacing={2}>
            <Grid item xs={1} />
            <Grid className={classes.title} item xs={11}>
                Parent Portal
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8} className={classes.palGrid}>
                <Paper elevation={0} className={classes.root}>
                    <Grid container>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormLabel onClick={handleOpenPI} focused={false}>
                                    Edit Personal Information{" "}
                                    <Button theme={theme} onClick={handleOpenPI}>
                                        {" "}
                                        Edit{" "}
                                    </Button>
                                    </FormLabel>
                                    <FormHelperText>Edit name or country</FormHelperText>
                                    <TermsAndConditionsModal
                                        open={openTC}
                                        theme={theme}
                                        handleClose={handleCloseTC}
                                    />
                                    <PaymentSignUp
                                        open={openSubscribe}
                                        theme={theme}
                                        handleClose={handleCloseSubscribe}
                                    />
                                    <Divider className={classes.divider} />
                                    
                                    <FormLabel focused={false}>
                                    Allow access to XC Xplor{" "}
                                    <GreySwitch
                                        checked={state.onlineResources}
                                        onChange={(e) => handleOnlineResources(e.target.checked)}
                                        name="onlineResources"
                                    />
                                </FormLabel>
                                
                                <Divider className={classes.divider} />
                                <FormLabel focused={false}>
                                    Chat Permission{" "}
                                    <GreySwitch
                                        checked={state.chat}
                                        onChange={(e) => handleChatPermission(e.target.checked)}
                                        name="chat"
                                    />
                                </FormLabel>
                                <FormHelperText>
                                    Allows/disallow access to chat with XC Pals
                                </FormHelperText>
                                <Divider className={classes.divider} />
                                <FormLabel focused={false}>
                                    Hidden Profile{" "}
                                    <GreySwitch
                                        checked={state.hiddenProfile}
                                        onChange={(e) => handleHideProfile(e.target.checked)}
                                        name="hidden"
                                    />
                                </FormLabel>
                                <FormHelperText>
                                    Allows/disallow your kid to be added as XC Pals. Posted
                                    content will be visible to other users but they cannot add
                                    them.
                                </FormHelperText>
                                <Divider className={classes.divider} />
                                <FormLabel onClick={handleOpenTM} focused={false}>
                                    Manage Time{" "}
                                    <Button theme={theme} onClick={handleOpenTM}>
                                        {" "}
                                        Edit{" "}
                                    </Button>{" "}
                                </FormLabel>
                                <FormHelperText>
                                    {" "}
                                    Set a time limit for your kids to use the app{" "}
                                </FormHelperText>
                                <TimeManagementModal
                                    user={props.user}
                                    theme={theme}
                                    open={openTM}
                                    handleClose={handleCloseTM}
                                />
                                <Divider className={classes.divider} />
                                <FormLabel focused={false}>
                                    {" "}
                                    Subscribe{" "}
                                    <Button theme={theme} onClick={handleOpenSubscribe}>
                                        {" "}
                                        Subscribe{" "}
                                    </Button>
                                </FormLabel>
                                <Divider className={classes.divider} />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Paper>
            </Grid>
            </Grid>
        </div>
    );
};