import axios from "axios";

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";

import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "../Input/BootstrapInput";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const { REACT_APP_API_URL } = process.env;

export default function TimeManagementModal(props) {
  const marks = [
    {
      value: 1,
      label: "1h",
    },
    {
      value: 2,
      label: "2h",
    },
    {
      value: 3,
      label: "4h",
    },
    {
      value: 4,
      label: "6h",
    },
    {
      value: 5,
      label: "None",
    },
  ];
  const { user, theme } = props;

  const useStyles = makeStyles((theme2) => ({
    button: {
      width: "80px",
      height: "25px",
      borderRadius: "12px",
      fontSize: "10px",
      margin: "5px",
    },
    root: {
      minHeight: 500,
      minWidth: 258,
    },
    bullet: {
      minHeight: "100px",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 32,
      fontFamily: "GoodUnicorn",
      color: theme.primaryColor,
    },
    margin: {
      margin: theme2.spacing(1),
    },
    modal: {
      position: "absolute",
      width: 500,
      backgroundColor: theme2.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme2.shadows[5],
      padding: theme2.spacing(2, 4, 3),
      top: "20%",
      left: "30%",
      overflow: "scroll",
    },

    divider: {
      margin: 10,
    },
  }));

  const classes = useStyles();
  const [period, setPeriod] = useState(user?.settings?.schedule_type);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [selectedTimezone, setTimezone] = useState(user?.settings?.timezone);

  const timezones = [
    {
      offset: "GMT-12:00",
      name: "Etc/GMT-12",
    },
    {
      offset: "GMT-11:00",
      name: "Etc/GMT-11",
    },
    {
      offset: "GMT-11:00",
      name: "Pacific/Midway",
    },
    {
      offset: "GMT-10:00",
      name: "America/Adak",
    },
    {
      offset: "GMT-09:00",
      name: "America/Anchorage",
    },
    {
      offset: "GMT-09:00",
      name: "Pacific/Gambier",
    },
    {
      offset: "GMT-08:00",
      name: "America/Dawson_Creek",
    },
    {
      offset: "GMT-08:00",
      name: "America/Ensenada",
    },
    {
      offset: "GMT-08:00",
      name: "America/Los_Angeles",
    },
    {
      offset: "GMT-07:00",
      name: "America/Chihuahua",
    },
    {
      offset: "GMT-07:00",
      name: "America/Denver",
    },
    {
      offset: "GMT-06:00",
      name: "America/Belize",
    },
    {
      offset: "GMT-06:00",
      name: "America/Cancun",
    },
    {
      offset: "GMT-06:00",
      name: "America/Chicago",
    },
    {
      offset: "GMT-06:00",
      name: "Chile/EasterIsland",
    },
    {
      offset: "GMT-05:00",
      name: "America/Bogota",
    },
    {
      offset: "GMT-05:00",
      name: "America/Havana",
    },
    {
      offset: "GMT-05:00",
      name: "America/New_York",
    },
    {
      offset: "GMT-04:30",
      name: "America/Caracas",
    },
    {
      offset: "GMT-04:00",
      name: "America/Campo_Grande",
    },
    {
      offset: "GMT-04:00",
      name: "America/Glace_Bay",
    },
    {
      offset: "GMT-04:00",
      name: "America/Goose_Bay",
    },
    {
      offset: "GMT-04:00",
      name: "America/Santiago",
    },
    {
      offset: "GMT-04:00",
      name: "America/La_Paz",
    },
    {
      offset: "GMT-03:00",
      name: "America/Argentina/Buenos_Aires",
    },
    {
      offset: "GMT-03:00",
      name: "America/Montevideo",
    },
    {
      offset: "GMT-03:00",
      name: "America/Araguaina",
    },
    {
      offset: "GMT-03:00",
      name: "America/Godthab",
    },
    {
      offset: "GMT-03:00",
      name: "America/Miquelon",
    },
    {
      offset: "GMT-03:00",
      name: "America/Sao_Paulo",
    },
    {
      offset: "GMT-03:30",
      name: "America/St_Johns",
    },
    {
      offset: "GMT-02:00",
      name: "America/Noronha",
    },
    {
      offset: "GMT-01:00",
      name: "Atlantic/Cape_Verde",
    },
    {
      offset: "GMT",
      name: "Europe/Belfast",
    },
    {
      offset: "GMT",
      name: "Africa/Abidjan",
    },
    {
      offset: "GMT",
      name: "Europe/Dublin",
    },
    {
      offset: "GMT",
      name: "Europe/Lisbon",
    },
    {
      offset: "GMT",
      name: "Europe/London",
    },
    {
      offset: "UTC",
      name: "UTC",
    },
    {
      offset: "GMT+01:00",
      name: "Africa/Algiers",
    },
    {
      offset: "GMT+01:00",
      name: "Africa/Windhoek",
    },
    {
      offset: "GMT+01:00",
      name: "Atlantic/Azores",
    },
    {
      offset: "GMT+01:00",
      name: "Atlantic/Stanley",
    },
    {
      offset: "GMT+01:00",
      name: "Europe/Amsterdam",
    },
    {
      offset: "GMT+01:00",
      name: "Europe/Belgrade",
    },
    {
      offset: "GMT+01:00",
      name: "Europe/Brussels",
    },
    {
      offset: "GMT+02:00",
      name: "Africa/Cairo",
    },
    {
      offset: "GMT+02:00",
      name: "Africa/Blantyre",
    },
    {
      offset: "GMT+02:00",
      name: "Asia/Beirut",
    },
    {
      offset: "GMT+02:00",
      name: "Asia/Damascus",
    },
    {
      offset: "GMT+02:00",
      name: "Asia/Gaza",
    },
    {
      offset: "GMT+02:00",
      name: "Asia/Jerusalem",
    },
    {
      offset: "GMT+03:00",
      name: "Africa/Addis_Ababa",
    },
    {
      offset: "GMT+03:00",
      name: "Asia/Riyadh89",
    },
    {
      offset: "GMT+03:00",
      name: "Europe/Minsk",
    },
    {
      offset: "GMT+03:30",
      name: "Asia/Tehran",
    },
    {
      offset: "GMT+04:00",
      name: "Asia/Dubai",
    },
    {
      offset: "GMT+04:00",
      name: "Asia/Yerevan",
    },
    {
      offset: "GMT+04:00",
      name: "Europe/Moscow",
    },
    {
      offset: "GMT+04:30",
      name: "Asia/Kabul",
    },
    {
      offset: "GMT+05:00",
      name: "Asia/Tashkent",
    },
    {
      offset: "GMT+05:30",
      name: "Asia/Kolkata",
    },
    {
      offset: "GMT+05:45",
      name: "Asia/Katmandu",
    },
    {
      offset: "GMT+06:00",
      name: "Asia/Dhaka",
    },
    {
      offset: "GMT+06:00",
      name: "Asia/Yekaterinburg",
    },
    {
      offset: "GMT+06:30",
      name: "Asia/Rangoon",
    },
    {
      offset: "GMT+07:00",
      name: "Asia/Bangkok",
    },
    {
      offset: "GMT+07:00",
      name: "Asia/Novosibirsk",
    },
    {
      offset: "GMT+08:00",
      name: "Etc/GMT+8",
    },
    {
      offset: "GMT+08:00",
      name: "Asia/Hong_Kong",
    },
    {
      offset: "GMT+08:00",
      name: "Asia/Krasnoyarsk",
    },
    {
      offset: "GMT+08:00",
      name: "Australia/Perth",
    },
    {
      offset: "GMT+08:45",
      name: "Australia/Eucla",
    },
    {
      offset: "GMT+09:00",
      name: "Asia/Irkutsk",
    },
    {
      offset: "GMT+09:00",
      name: "Asia/Seoul",
    },
    {
      offset: "GMT+09:00",
      name: "Asia/Tokyo",
    },
    {
      offset: "GMT+09:30",
      name: "Australia/Adelaide",
    },
    {
      offset: "GMT+09:30",
      name: "Australia/Darwin",
    },
    {
      offset: "GMT+09:30",
      name: "Pacific/Marquesas",
    },
    {
      offset: "GMT+10:00",
      name: "Etc/GMT+10",
    },
    {
      offset: "GMT+10:00",
      name: "Australia/Brisbane",
    },
    {
      offset: "GMT+10:00",
      name: "Australia/Hobart",
    },
    {
      offset: "GMT+10:00",
      name: "Asia/Yakutsk",
    },
    {
      offset: "GMT+10:30",
      name: "Australia/Lord_Howe",
    },
    {
      offset: "GMT+11:00",
      name: "Asia/Vladivostok",
    },
    {
      offset: "GMT+11:30",
      name: "Pacific/Norfolk",
    },
    {
      offset: "GMT+12:00",
      name: "Etc/GMT+12",
    },
    {
      offset: "GMT+12:00",
      name: "Asia/Anadyr",
    },
    {
      offset: "GMT+12:00",
      name: "Asia/Magadan",
    },
    {
      offset: "GMT+12:00",
      name: "Pacific/Auckland",
    },
    {
      offset: "GMT+12:45",
      name: "Pacific/Chatham",
    },
    {
      offset: "GMT+13:00",
      name: "Pacific/Tongatapu",
    },
    {
      offset: "GMT+14:00",
      name: "Pacific/Kiritimati",
    },
  ];

  const [dailyHours, setDailyHours] = useState(user?.settings?.daily_hours);

  const handleSetPeriod = async (newValue) => {
    await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
      method: "POST",
      data: { schedule_type: newValue },
    });
    setPeriod(newValue);
  };

  const handleSetStartTime = async (value) => {
    setStartTime(value);
    await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
      method: "POST",
      data: { start_time: value },
    });
  };
  const handleSetEndTime = async (value) => {
    setEndTime(value);
    await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
      method: "POST",
      data: { end_time: value },
    });
  };

  const handleSetTimezone = async (value) => {
    setTimezone(value);
    console.log(value);
    await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
      method: "POST",
      data: { timezone: value },
    });
  };

  const handleSliderChange = async (newValue) => {
    console.log(newValue);
    switch (newValue) {
      case 1:
        setDailyHours(1);
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
          method: "POST",
          data: { daily_hours: 1 },
        });
        break;
      case 2:
        setDailyHours(2);
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
          method: "POST",
          data: { daily_hours: 2 },
        });
        break;
      case 3:
        setDailyHours(3);
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
          method: "POST",
          data: { daily_hours: 3 },
        });
        break;
      case 4:
        setDailyHours(4);
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
          method: "POST",
          data: { daily_hours: 4 },
        });
        break;
      case 5:
        setDailyHours(5);
        await axios(`${REACT_APP_API_URL}/user_settings/${user.id}`, {
          method: "POST",
          data: { daily_hours: 5 },
        });
        break;
      default:
        setDailyHours(5);
    }
  };
  const modalBody = (
    <div className={classes.modal}>
      <Grid container>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography className={classes.title} gutterBottom>
            Manage Time
          </Typography>
        </div>
        <Divider />
        <Typography>Time Limit</Typography>
        <div style={{ width: "100%" }} />
        <Typography>{dailyHours} hrs</Typography>
        <Slider
          defaultValue={5}
          value={dailyHours}
          aria-labelledby="discrete-slider-custom"
          max={5}
          step={null}
          style={{ color: theme.primaryColor }}
          marks={marks}
          onChange={(e, newValue) => handleSliderChange(newValue)}
        />
        <Grid item xs={12} />

        <Divider
          style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        />
        <h3>Schedule</h3>

        <Grid container style={{ marginTop: "10px" }}>
          <Grid item xs={6}>
            <FormLabel component="legend">Days of the Week</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="period"
                name="period"
                value={period}
                onChange={(e, newValue) => handleSetPeriod(newValue)}
              >
                <FormControlLabel
                  value="daily"
                  control={<Radio style={user.gender === "M" ? {
                      color: "#3344cc",
                  } : {
                      color: "#eb4c8a"
                  }} />}
                  label="Daily"
                />
                <FormControlLabel
                  value="weekday"
                  control={<Radio style={user.gender === "M" ? {
                      color: "#3344cc",
                  } : {
                      color: "#eb4c8a"
                  }} />}
                  label="Weekday"
                />
                <FormControlLabel
                  value="weekends"
                  control={<Radio style={user.gender === "M" ? {
                      color: "#3344cc",
                  } : {
                      color: "#eb4c8a"
                  }} />}
                  label="Weekends"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} />

          <Divider
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          />

          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <FormLabel component="legend">From</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormControlLabel
                  value="daily"
                  control={
                    <TextField
                      id="time"
                      type="time"
                      defaultValue="07:30"
                      name="startTime"
                      value={startTime}
                      max={endTime}
                      onChange={(e) =>
                        e.target.value < endTime
                          ? handleSetStartTime(e.target.value)
                          : ""
                      }
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                        max: endTime,
                        // 5 min
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          />

          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <FormLabel component="legend">To</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormControlLabel
                  value="daily"
                  control={
                    <TextField
                      id="time"
                      type="time"
                      defaultValue="07:30"
                      name="endTime"
                      value={endTime}
                      min={startTime}
                      onChange={(e) =>
                        e.target.value > startTime
                          ? handleSetEndTime(e.target.value)
                          : ""
                      }
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: startTime,
                        step: 300, // 5 min
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth className={classes.margin}>
            <Autocomplete
              options={timezones.map((tz) => tz.name)}
              value={selectedTimezone}
              onChange={(event, newValue) => {
                handleSetTimezone(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  label="Timezone"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <div style={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
          <Button theme={theme} onClick={props.handleClose}>
            {" "}
            Done{" "}
          </Button>
        </div>
      </Grid>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      style={{ overflow: "scroll" }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalBody}
    </Modal>
  );
}
