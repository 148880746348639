import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Paper from "@material-ui/core/Paper";
import Button from "../Input/Button";
import LifeTopicPost from "./LifeTopicPost";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";


const { REACT_APP_API_URL } = process.env;


// Define styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
    root: {
        minWidth: 275,
        minHeight: 100,
        marginTop: "50px",
    },
    input: {
        height: "100%",
        resize: "none",
        overflow: "scroll",
    },
    title: {
        fontSize: 32,
        fontFamily: "GoodUnicorn",
        color: props => props.theme.primaryColor,
        zIndex: 1,
    },
    homeGrid: {
        "&::before": {
            content: "' '",
            background: props => `url(${props.theme.backgroundLogo}) no-repeat center center fixed`,
            opacity: ".6",
            backgroundSize: "cover",
            position: "absolute",
            top: "160px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            zIndex: -1,
        },
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }
});

export default function Home({ user, showNotification, theme }) {
    console.log("🚀 ~ file: Home.jsx:59 ~ Home ~ user:", {user});
    const classes = useStyles({ theme });
    // State to hold the posts for the latest life topic
    const [lifeTopicPosts, setLifeTopicPosts] = useState([]);
    // State to hold the latest life topic
    const [latestLifeTopic, setLatestLifeTopic] = useState(null);
    // State to track loading status
    const [isLoading, setIsLoading] = useState(true);
    
    // Function to fetch life topics and posts
    const fetchLifeTopicData = useCallback(async () => {
        if (!user) return;
        
        setIsLoading(true);
        try {
            // Fetch life topics
            const topicsResponse = await axios.get(`${REACT_APP_API_URL}/life_topics`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("JWT_TOKEN")}`,
                }
            });
            
            if (topicsResponse.data.length === 0) {
                console.log("No life topics found.");
                setIsLoading(false);
                return;
            }
            
            // Get the latest life topic
            const latest = topicsResponse.data[topicsResponse.data.length - 1];
            setLatestLifeTopic(latest);
            
            // Fetch posts for the latest life topic
            const postsResponse = await axios.get(`${REACT_APP_API_URL}/life_topic_posts/${latest.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("JWT_TOKEN")}`,
                }
            });
            
            const sortedPosts = postsResponse.data.sort((a, b) => 
                new Date(b.created_at) - new Date(a.created_at)
            );
            
            setLifeTopicPosts(sortedPosts);
        } catch (error) {
            console.error("Error fetching life topic data:", error);
            showNotification("Failed to load life topic data", "error");
        } finally {
            setIsLoading(false);
        }
    }, [user, showNotification]);
    
    // Fetch life topic data when the component mounts or when user changes
    useEffect(() => {
        if (user) {
            fetchLifeTopicData();
        }
    }, [user, fetchLifeTopicData]);
    
    // Function to upload a life topic image
    const uploadLifeTopicImage = async (file, imageNumber) => {
        let formData = new FormData();
        formData.append("file", file);
        
        showNotification("Uploading...", "warning");
        try {
            await axios.post(
                `${REACT_APP_API_URL}/life_topic/upload_${imageNumber}/${latestLifeTopic?.id}`,
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("JWT_TOKEN")}`,
                        "Content-Type": "multipart/form-data"
                    }
                }
            );
            showNotification("Upload successful", "success");
            fetchLifeTopicData(); // Refresh data after upload
        } catch (error) {
            showNotification("Failed to upload.", "error");
        }
    };
    
    // Function to create a new post
    const makePost = async () => {
        let content = document.getElementById("contained-button-file").files[0];
        
        if (!content) {
            showNotification("Please attach a file before uploading!", "warning");
            return;
        }
        
        let status = document.getElementById("status").value;
        
        let formData = new FormData();
        formData.append("file", content);
        formData.append("status", status);
        
        showNotification("Uploading...", "warning");
        try {
            const result = await axios.post(
                `${REACT_APP_API_URL}/life_topic_posts/${latestLifeTopic?.id}`,
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("JWT_TOKEN")}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            showNotification("Post created successfully", "success");
            setLifeTopicPosts(prevPosts => [result.data, ...prevPosts]);
        } catch (error) {
            showNotification(error.response?.data?.message || "Failed to create post", "error");
        }
    };
    
    // Function to remove a post
    const removePost = (postId) => {
        setLifeTopicPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
    };
    
    // Show loading indicator while data is being fetched
    if (!user || isLoading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
                <Typography>Loading..</Typography>
            </div>
        );
    }
    
    return (
        <div>
            <Grid container spacing={2} className={classes.homeGrid}>
                {/* Left image */}
                <Grid item xs={4} style={{ zIndex: 1, margin: "auto", paddingTop: "55px" }}>
                    {user.is_mentor && !latestLifeTopic?.image_1 ? (
                        <Card style={{ textAlign: "center", margin: "auto" }}>
                            <input
                                accept="image/*"
                                id="life-topic-first-image-upload"
                                onChange={(event) => uploadLifeTopicImage(event.target.files[0], 1)}
                                type="file"
                                style={{ display: "none" }}
                            />
                            <label htmlFor="life-topic-first-image-upload">
                                <Button style={{ fontWeight: "bold" }} theme={theme}>
                                    Upload
                                </Button>
                            </label>
                        </Card>
                    ) : (
                        latestLifeTopic?.image_1 && (
                            <CardMedia
                                style={{
                                    width: "95%",
                                    objectFit: "fill",
                                    margin: "auto",
                                    maxHeight: "320px",
                                    borderRadius: "4px",
                                }}
                                component="img"
                                image={latestLifeTopic.image_1}
                                title="Life Topic Image 1"
                            />
                        )
                    )}
                </Grid>
                
                {/* Center content */}
                <Grid className={classes.title} item xs={4}>
                    <Typography className={classes.title} style={{ textAlign: "center" }}>
                        Life Topic
                        <br />
                        {latestLifeTopic?.created_at && new Date(latestLifeTopic.created_at).toLocaleDateString()}
                    </Typography>
                    {latestLifeTopic?.url && (
                        <iframe
                            type="text/html"
                            src={`${latestLifeTopic.url}?rel=0`}
                            width="100%"
                            height="100%"
                            allowFullScreen
                            title="Life Topic Video"
                        />
                    )}
                </Grid>
                
                {/* Right image */}
                <Grid item xs={4} style={{ zIndex: 1, margin: "auto", paddingTop: "55px" }}>
                    {user.is_mentor && !latestLifeTopic?.image_2 ? (
                        <Card style={{ textAlign: "center" }}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                onChange={(event) => uploadLifeTopicImage(event.target.files[0], 2)}
                                type="file"
                                id="life-topic-second-image-upload"
                                style={{ display: "none" }}
                            />
                            <label htmlFor="life-topic-second-image-upload">
                                <Button style={{ fontWeight: "bold" }} theme={theme}>
                                    Upload
                                </Button>
                            </label>
                        </Card>
                    ) : (
                        latestLifeTopic?.image_2 && (
                            <CardMedia
                                style={{
                                    width: "95%",
                                    objectFit: "fill",
                                    margin: "auto",
                                    maxHeight: "320px",
                                    borderRadius: "4px",
                                }}
                                component="img"
                                image={latestLifeTopic.image_2}
                                title="Life Topic Image 2"
                            />
                        )
                    )}
                </Grid>
                
                <Grid item xs={4} />
                
                {/* Post creation form for non-parent users */}
                {user?.is_kid && (
                    <Grid item justifyContent="center" xs={4} spacing={3} style={{ zIndex: 1, marginTop: '50px' }}>
                        <Card className={classes.root}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Typography style={{ margin: "10px", fontWeight: "bold" }}>
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Paper elevation={5} style={{ width: "100%", textAlign: "center", margin: "10px" }}>
                                        <TextField
                                            style={{ width: "95%", height: "30px" }}
                                            id="status"
                                            variant="filled"
                                            InputLabelProps={{
                                                style: { height: "30px" },
                                                shrink: true
                                            }}
                                            InputProps={{
                                                style: {
                                                    height: "35px",
                                                    backgroundColor: "white",
                                                    padding: 0,
                                                },
                                                disableUnderline: true
                                            }}
                                            inputProps={{
                                                style: { paddingTop: "10px", paddingBottom: "0px" },
                                            }}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        style={{
                                            margin: "15px",
                                            maxHeight: "32px",
                                            maxWidth: "32px",
                                        }}
                                        onClick={makePost}
                                    >
                                        <img
                                            style={{
                                                maxHeight: "24px",
                                                maxWidth: "24px",
                                                marginBottom: "5px"
                                            }}
                                            src="/icons/001-paper-plane.png"
                                            alt="Send"
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={3} />
                                <Grid item xs={2} />
                                <Grid item xs={4}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button style={{ fontWeight: "bold" }} theme={theme}>
                                            Upload
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={4} />
            </Grid>
            
            {/* Display life topic posts */}
            <Grid container spacing={2}>
                <Grid item xs={4} />
                {lifeTopicPosts.map((post) => (
                    <LifeTopicPost
                        key={post.id}
                        showNotification={showNotification}
                        removePost={removePost}
                        theme={theme}
                        post={post}
                        user={user}
                    />
                ))}
            </Grid>
        </div>
    );
}