import axios from "axios";
import Echo from "laravel-echo";
import { useEffect, useState, useCallback, useMemo } from "react";
import Routes from "./Routes";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./App.css";

const { REACT_APP_API_URL } = process.env;

// Main App component
function App() {
    const { user, setUser, parentUser, kids, selectChild, setSelectChild, isDataFetched } = useAuth();
    console.log("🚀 ~ file: App.jsx:15 ~ App ~ TOP LEVEL PROP ~ user:", {user});
    const { open, message, severity, showNotification, hideNotification } = useNotification();
    
    // Memoized theme to prevent unnecessary re-renders
    const theme = useMemo(() => {
        return user?.gender === "M"
            ? {
                primaryColor: "#3344cc",
                secondaryColor: "#ababdc",
                barColor: "hsl(29deg 91% 79%)",
                backgroundLogo: "/images/m-racing.jpg"
            }
            : {
                primaryColor: "#eb4c8a",
                secondaryColor: "#fccfdf",
                barColor: "#fcedf3",
                backgroundLogo: "/images/f-meadow.jpg"
            };
    }, [user?.gender]);
    
    // Set up Pusher and Echo when user is authenticated
    useEffect(() => {
        if (user) {
            window.Pusher = require("pusher-js");
            window.Pusher.logToConsole = true;
            
            window.Echo = new Echo({
                authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
                broadcaster: "pusher",
                key: "5f75a4a24bbf5d8c0002",
                cluster: "us3",
                forceTLS: true,
                auth: {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("JWT_TOKEN")}`
                    }
                }
            });
            console.log("Pusher and Echo initialized with valid token: ", localStorage.getItem("JWT_TOKEN") !== undefined);
        }
    }, [user]);
    
    // Callback for selecting a child user
    const proceedWithChild = useCallback((selectedUser) => {
        setSelectChild(false);
        setUser({ ...selectedUser, isParent: true, parent: parentUser });
    }, [parentUser, setUser]);
    
    
    return (
        <>
            <Routes
                user={user}
                parent={parentUser}
                kids={kids}
                setUser={setUser}
                selectChild={selectChild}
                proceedWithChild={proceedWithChild}
                theme={theme}
                showNotification={showNotification}
                isDataFetched={isDataFetched}
            />
            <Snackbar open={open} autoHideDuration={6000} onClose={hideNotification}>
                <MuiAlert elevation={6} variant="filled" onClose={hideNotification} severity={severity}>
                    {message}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

// Custom hook for managing authentication state
function useAuth() {
    const [user, setUser] = useState(null);
    const [parentUser, setParentUser] = useState(null);
    const [kids, setKids] = useState(null);
    const [selectChild, setSelectChild] = useState(true);
    const [isDataFetched, setIsDataFetched] = useState(false);
    
    const fetchUser = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlToken = urlParams.get('token') ? urlParams.get('token') : false;
        console.log("🚀 ~ file: App.jsx:97 ~ fetchUser ~ urlToken:", urlToken);
        const localStorageToken = localStorage.getItem('JWT_TOKEN') ? localStorage.getItem('JWT_TOKEN') : false;
        console.log("🚀 ~ file: App.jsx:99 ~ fetchUser ~ localStorageToken:", localStorageToken);
        
        if (!localStorageToken && !urlToken) {
            console.log("No token found in URL or local storage.");
            return;
        }
        
        axios.defaults.headers.common["Authorization"] = `Bearer ${localStorageToken || urlToken}`;
        window.localStorage.setItem("JWT_TOKEN", localStorageToken || urlToken);
        
        try {
            // Validate the token
            const validationResponse = await axios.get(`${REACT_APP_API_URL}/auth/verify-token`);
            
            console.log("🚀 ~ file: App.jsx:112 ~ fetchUser ~ validationResponse:", validationResponse);
            if (validationResponse?.data?.status === "success") {
                // Fetch user data
                const response = await axios.get(`${REACT_APP_API_URL}/auth/me`);
                const user = response.data;
                console.log("🚀 ~ file: App.jsx:116 ~ fetchUser ~ user:", {user});
                const { children, token } = response.data || {};
                console.log("🚀 ~ file: App.jsx:118 ~ fetchUser ~ children:", children);
                setKids(children);
                
                if (!user) {
                    console.log("No user data found in response.");
                    return;
                }
                setUser({ ...user, access_token: token, is_parent: user.roles.filter((role) => role.name === "parent")});
                
                if (children.length > 0) {
                    console.log("🚀 ~ file: App.jsx:128 ~ fetchUser ~ Total Children:", children.length);
                    setParentUser(user);
                    setSelectChild(true);
                } else if (user.roles?.some((role) => role.name === "mentor")) {
                    setUser({ ...user, access_token: token, is_mentor: true });
                } else {
                    setUser({ ...user, access_token: token, is_kid: true });
                }
                
                // Save the user data to local storage
                window.localStorage.setItem("USER", JSON.stringify(user));
                window.localStorage.setItem("CHILDREN", JSON.stringify(kids));
                window.localStorage.setItem("SETTINGS", JSON.stringify(user.settings));
            }
            else {
                console.log('Invalid token');
                setUser(null);
            }
        } catch (error) {
            console.log("Error fetching user", error);
            setUser(null);
        } finally {
            setIsDataFetched(true);
        }
    }, []);
    
    
    useEffect(() => {
        fetchUser();
    }, [fetchUser]);
    
    return { user, setUser, parentUser, kids, selectChild, setSelectChild, isDataFetched };
};

// Custom hook for notifications
function useNotification() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("info");
    
    const showNotification = useCallback((message, severity = "info") => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    }, []);
    
    const hideNotification = useCallback((event, reason) => {
        if (reason === "clickaway") return;
        setOpen(false);
    }, []);
    
    return { open, message, severity, showNotification, hideNotification };
};

export default App;