import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

export default function NavTabs(props) {
    const history = useHistory();
    const { user, panels, theme, value, setValue } = props;
    console.log("🚀 ~ file: NavTabs.jsx:47 ~ NavTabs ~ user:", user);
    
    const handleChange = (event, newValue) => {
        let path = "";
        switch (newValue) {
            case 0:
                path = "/dashboard/home";
                break;
            case 1:
                path = "/dashboard/life_topics";
                break;
            case 2:
                path = "/dashboard/xc_pals";
                break;
            case 3:
                path = "/dashboard/journal";
                break;
            case 4:
                path = "/dashboard/resources";
                break;
            case 5:
                path = "/dashboard/parents";
                break;
        }
        
        if (path === "/dashboard/resources" && user?.settings?.online_resources === 0) {
            console.log("🚀 ~ The /dashboard/resources React route requires user?.settings?.online_resources === 1. This user does not have the required access to online resouces.");
            return;
        }
        if (path === "/dashboard/parents" && user.children?.length === 0) {
            console.log("🚀 ~ The /dashboard/parents React route requires user.isParent === true. This user is not a parent.");
            return;
        }
        
        history.push(path);
        setValue(newValue);
    };
    
    const useStyles = makeStyles({
        root: {
            flexGrow: 1,
            maxWidth: "100%"
        },
        appbar: {
            backgroundColor: theme.barColor, // '#fcedf3',
            color: theme.primaryColor, // '#565455',
            textAlign: "center"
        }
    });
    const classes = useStyles();
    
    const XcTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            fontWeight: "bold",
            width: "10%"
        }
    }))((props) => <Tab disableRipple {...props} />);
    
    const tabs = [
        <XcTab
            label="Home"
            icon={
                <img
                    style={{ heigh: "24px", width: "24px" }}
                    src={
                        user.gender === "F"
                            ? "/icons/006-house.png"
                            : "/icons/006-houseb.png"
                    }
                />
            }
            {...a11yProps(0)}
        />,
        <XcTab
            label="Life Topics"
            icon={
                <img
                    style={{
                        heigh: "24px",
                        width: "24px",
                        filter: "invert(70%) sepia(39%) saturate(6156%) hue-rotate(334deg) brightness(102%) contrast(101%)",
                    }}
                    src="/icons/topics.svg"
                />
            }
            {...a11yProps(1)}
        />,
        <XcTab
            label="My XC Pals"
            icon={
                <img
                    style={{ heigh: "24px", width: "24px" }}
                    src="/icons/friends.svg"
                />
            }
            {...a11yProps(2)}
        />,
        <XcTab
            label="Journal"
            icon={
                <img
                    style={{ heigh: "24px", width: "24px" }}
                    src="/icons/journal.svg"
                />
            }
            {...a11yProps(3)}
        />,
        <XcTab
            label="XC Xplor"
            icon={
                <img
                    style={{ heigh: "24px", width: "24px" }}
                    src="/icons/xplor.svg"
                />
            }
            {...a11yProps(4)}
        />,
        user.children.length > 0 && (
            <XcTab
                label="Parent Portal"
                icon={
                    <img
                        style={{
                            heigh: "24px",
                            width: "24px",
                            filter: "invert(61%) sepia(38%) saturate(2764%) hue-rotate(227deg) brightness(95%) contrast(89%)",
                        }}
                        src="/icons/parents.svg"
                    />
                }
                {...a11yProps(5)}
            />
        )
    ];
    
    //* This was causing a double render issue on every tab.
    // const renderPanels = tabs.map((tab, index) => {
    //     return (
    //         <TabPanel value={value} index={index}>
    //             {panels[index]}
    //         </TabPanel>
    //     );
    // });
    
    return (
        <div className={classes.root}>
            <AppBar className={classes.appbar} position="static">
                <Tabs
                    centered
                    textColor="primary"
                    indicatorColor="blue"
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                >
                    {tabs}
                </Tabs>
            </AppBar>
            {/* {renderPanels} */}
        </div>
    );
};