import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DialogSelect from '../Input/DialogSelect';
import Button from '../Input/Button';

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
    journalBase: {
        minWidth: 275,
        minHeight: 500,
    },
    input: {
        height: '100%',
        resize: 'none',
        overflow: 'scroll',
    },
    title: {
        fontSize: 32,
        fontFamily: 'GoodUnicorn',
        color: theme.primaryColor,
        background: "#f8f8f8",
        padding: "0px !important",
        margin: "0px !important"
    }
}));

export default function Journal({ user, theme }) {
    const classes = useStyles();
    
    const [journalEntry, setJournalEntry] = useState('');
    const [journalStyle, setJournalStyle] = useState('');
    const [journalStyleCSS, setJournalStyleCSS] = useState({background: ''});
    const [characterCount, setCharacterCount] = useState(0);
    const [journalEntries, setJournalEntries] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    
    useEffect(() => {
        fetchJournalEntries();
        fetchJournalStylePreference();
    }, [searchParams]);
    
    useEffect(() => {
        updateJournalStyleCSS();
    }, [journalStyle]);
    
    const fetchJournalEntries = async () => {
        const result = await axios.get(`${REACT_APP_API_URL}/journal_entries/${user.id}`, { params: searchParams });
        setJournalEntries(result.data);
    };
    
    const fetchJournalStylePreference = async () => {
        const result = await axios.get(`${REACT_APP_API_URL}/user/${user.id}/getJournalStylePreference`);
        setJournalStyle(result.data[0].journal_style_choice);
    };
    
    const updateJournalStyleCSS = () => {
        if (['ocean', 'hearts', 'nature'].includes(journalStyle)) {
        setJournalStyleCSS({ background: `url('/images/journal/${journalStyle}.jpg')` });
        } else if (journalStyle) {
        setJournalStyleCSS({ background: `url('/images/journal/${journalStyle}.png')` });
        }
    };
    
    const handleJournalInput = (e) => {
        const newEntry = e.target.value.slice(0, 2000);
        setJournalEntry(newEntry);
        setCharacterCount(newEntry.length);
    };
    
    const submitJournalEntry = async () => {
        if (user && journalEntry.trim()) {
        await axios.post(`${REACT_APP_API_URL}/journal`, { content: journalEntry });
        setJournalEntry('');
        setCharacterCount(0);
        fetchJournalEntries();
        }
    };

    const saveJournalStyle = async (newStyle) => {
        try {
        await axios.post(`${REACT_APP_API_URL}/user/${user.id}/updateJournalStylePreference`, { item: newStyle });
        setJournalStyle(newStyle);
        } catch (error) {
        console.error("Failed to update journal style:", error);
        alert("Error: Failed to update journal style. Please try again.");
        }
    };

    const filterByMonth = (e, month) => {
        if (month.props.children === 'Clear') {
        setSearchParams({ ...searchParams, month: undefined });
        } else {
        setSearchParams({
            ...searchParams,
            month: months.indexOf(month.props.children) + 1,
        });
        }
    };

    const filterByYear = (e, year) => {
        if (year.props.children === 'Clear') {
        setSearchParams({ ...searchParams, year: undefined });
        } else {
        setSearchParams({ ...searchParams, year: year.props.children });
        }
    };

    const filterByDate = (e) => {
        if (e.target.value === 'Clear') {
        setSearchParams({ ...searchParams, date: undefined });
        } else {
        setSearchParams({ ...searchParams, date: e.target.value });
        }
    };
    
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const journalBordersDTO = {
        heading: 'Change your journal border?',
        defaultLabel: 'options',
        items: {
            frame: { name: "Frame", value: "frame" },
            nature: { name: 'Nature', value: 'nature' },
            ocean: { name: 'Ocean', value: 'ocean' },
            birds: { name: 'Birds', value: 'birds' },
            birds_2: { name: 'Birds II', value: 'birds_2' },
            boats: { name: 'Boats', value: 'boats' },
            butterflies: { name: 'butterflies', value: 'butterflies' },
            bugs: { name: 'Bugs', value: 'bugs' },
            cars: { name: 'Cars', value: 'cars' },
            cats: { name: 'Cats', value: 'cats' },
            cats_2: { name: 'Cats II', value: 'cats_2' },
            color_bunnies: { name: 'Bunnies', value: 'color_bunnies' },
            dog: { name: 'Dogs', value: 'dog' },
            ducks: { name: 'Ducks', value: 'ducks' },
            fish: { name: 'Fish', value: 'fish' },
            fish_tile: { name: 'Fish Tiled', value: 'fish_tile' },
            flowers: { name: 'Flowers', value: 'flowers' },
            flowers_2: { name: 'Flowers II', value: 'flowers_2' },
            frogs: { name: 'Frogs', value: 'frogs' },
            grasshopper: { name: 'Grasshoppers', value: 'grasshopper' },
            horses: { name: 'Horses', value: 'horses' },
            lucy: { name: 'Lucy', value: 'lucy' },
            swans: { name: 'Swans', value: 'swans' },
            three_birds: { name: 'Birds III', value: 'three_birds' }
        }
    };
    
    return (
        <div style={{marginTop: "10px"}}>
            <Grid
                container
                spacing={2}
                style={{
                border: journalStyleCSS.border,
                borderColor: journalStyleCSS.borderColor,
                borderStyle: journalStyleCSS.borderStyle,
                borderWidth: journalStyleCSS.borderWidth,
                background: journalStyleCSS.background,
                borderImageSource: journalStyleCSS.borderImageSource,
                borderImageSlice: journalStyleCSS.borderImageSlice,
                borderImageRepeat: journalStyleCSS.borderImageRepeat,
                borderImageWidth: journalStyleCSS.borderImageWidth,
                }}
                id={"HOC"}
            >
                <Grid className={classes.title} item xs={9}>
                    <h4 style={{ color: theme.primaryColor, paddingLeft: "20px", background: "#f8f8f8", margin: "0px"}}>
                        Journal
                    </h4>
                    <h5 style={{ color: theme.primaryColor, paddingLeft: "20px", background: "#f8f8f8", margin: "0px" }}>
                        {new Date().toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        })}
                        {' '}
                        - Today
                    </h5>
                </Grid>
                <Grid item xs={3} style={{ background: "#f8f8f8" }}>
                    {user && (
                        <DialogSelect
                        DTO={journalBordersDTO}
                        saveJournalStyle={saveJournalStyle}
                        user={user}
                        theme={theme}
                        />
                    )}
                </Grid>
                
                <Grid item xs={3} />
                
                <Grid
                    item
                    justifyContent="center"
                    xs={6}
                    spacing={3}
                >
                    <Card
                        className={classes.journalBase}
                    >
                        {user && (
                            <TextField
                            multiline
                            fullWidth
                            className={classes.input}
                            inputProps={{
                                style: {
                                height: '500px',
                                padding: '0 14px',
                                overflow: 'scroll',
                                }
                            }}
                            placeholder="What happened today?"
                            variant="outlined"
                            value={journalEntry}
                            onChange={handleJournalInput}
                            />
                        )}
                    </Card>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={1} style={{ background: "#f8f8f8" }}>
                    {' '}
                    <Typography style={{ paddingLeft: '5px' }}>
                        {' '}
                        {characterCount}
                        /2000
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ background: "#f8f8f8" }}/>
                
                <Grid item xs={1} style={{ background: "#f8f8f8" }}>
                    <Button
                        theme={theme}
                        style={{ fontWeight: 'bold' }}
                        onClick={submitJournalEntry}
                    >
                        {' '}
                        Done
                        {' '}
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ background: "#f8f8f8" }} />
                <Grid item xs={3} />
                <Grid item xs={6} style={{ background: "#f8f8f8" }}>
                Search By
                <Select
                    style={{ margin: '5px' }}
                    displayEmpty
                    value={searchParams.month || ''}
                    onChange={filterByMonth}
                    renderValue={(value) => months[value - 1] || 'Month'}
                >
                {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                    'Clear'
                ].map((month) => (
                    <MenuItem key={month}>{month}</MenuItem>
                ))}
                </Select>
                or
                <TextField
                    style={{ margin: '5px' }}
                    id="date"
                    placeholder="Date"
                    type="date"
                    onChange={filterByDate}
                    value={searchParams.date}
                    className={classes.textField}
                    inputProps={{ placeholder: 'Date' }}
                    InputLabelProps={{
                    shrink: true
                    }}
                />
                or
                <Select
                    style={{ margin: '5px' }}
                    displayEmpty
                    value={searchParams.year || ''}
                    onChange={filterByYear}
                    renderValue={(value) => value || 'Year'}
                >
                    {[2021, 2022, 'Clear'].map((year) => (
                    <MenuItem key={year}>{`${year}`}</MenuItem>
                    ))}
                </Select>
                </Grid>
                <Grid item xs={12} />
            </Grid>
            
            <Grid container style={{ marginTop: '50px' }}>
                {journalEntries.map((journalEntry) => (
                <React.Fragment key={journalEntry.id}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                    <h2>
                        {new Date(journalEntry.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        })}
                    </h2>
                    <Typography
                        style={{ overflowWrap: 'break-word', overflow: 'auto' }}
                    >
                        {journalEntry.content}
                    </Typography>
                    </Grid>
                    <Grid item xs={3} />
                </React.Fragment>
                ))}
            </Grid>
        </div>
    );
}