import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import BrAvatar from "../Profile/Avatar";
import Home from "../Profile/Home";
// import HomeBase from "../Profile/HomeBase";
import Journal from "../Profile/Journal";
import XCPals from "../Profile/XCPals";
import NavTabs from "./NavTabs";
import ParentsPortal from "../Profile/ParentsPortal";
import LifeTopics from "../Profile/LifeTopics";
import OnlineResources from "../Profile/OnlineResources";


const useStyles = makeStyles((theme) => ({
    badge: {
        right: 10,
        top: 11,
        border: `2px solid ${theme.primaryColor}`,
        padding: "0 4px",
        justifyContent: "flex-end",
    },
    notifBadge: {
        right: 1,
        top: 15,
        border: `2px solid ${theme.primaryColor}`,
        padding: "0 4px",
        justifyContent: "flex-end",
    },
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    topNav: {
        height: "75px",
        width: "100%",
    },
    control: {
        padding: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }
}));

export default function Dashboard(props) {
    const settings = JSON.parse(localStorage.getItem('SETTINGS') || 'null');
    const children = JSON.parse(localStorage.getItem('CHILDREN') || 'null');
    const { user: propsUser, showNotification, theme, isLoading: propsIsLoading, initialTab } = props;
    console.log("🚀 ~ file: Dashboard.jsx:60 ~ Dashboard ~ propsUser:", propsUser);
    
    const [user, setUser] = useState(propsUser);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [messageCount, setMessageCount] = useState(propsUser?.message_notifs?.length || 0);
    
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    
    useEffect(() => {
        if (!propsIsLoading && propsUser) {
            setUser(propsUser);
            setMessageCount(propsUser.message_notifs?.length || 0);
            setIsLoading(false);
        }
    }, [propsUser, propsIsLoading]);
    
    const handleTabChange = useCallback((newValue) => {
        setValue(newValue);
    }, []);
    
    useEffect(() => {
        const path = location.pathname.split('/').pop();
        switch (path) {
            case 'home':
                setValue(0);
                break;
            case 'life_topics':
                setValue(1);
                break;
            case 'xc_pals':
                setValue(2);
                break;
            case 'journal':
                setValue(3);
                break;
            case 'resources':
                setValue(4);
                break;
            case 'parents':
                setValue(5);
                break;
            default:
                setValue(0);
        }
    }, [location]);
    
    // useEffect(() => {
    //     if (user && user.id) {
    //         window.Echo.private(`messages.${user.id}`).listen(
    //             "SentMessage",
    //             (e) => {
    //                 if (e.message) {
    //                     console.log("heard a message of:", e);
    //                     setMessageCount(prevCount => prevCount + 1);
    //                 }
    //             }
    //         );
    //     }
    // }, [user]);
    
    const SimpleMenu = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [notifs, setNotifCount] = useState(null);
        
        // useEffect(() => {
        //     async function fetchNotifications() {
        //         if (user && user.parent) {
        //             try {
        //                 const result = await axios.get(
        //                     `${REACT_APP_API_URL}/pending_friends/${user.id}`
        //                 );
        //                 setNotifCount(result.data.length);
        //                 window.Echo.private(`notifications.${user.parent.id}`).listen(
        //                     "SentNotification",
        //                     (e) => {
        //                         console.log(e);
        //                         setNotifCount(e.data.count);
        //                     }
        //                 );
        //             } catch (error) {
        //                 console.error("Error fetching notifications:", error);
        //             }
        //         }
        //     }
        //     fetchNotifications();
        // }, [user]);
        
        // useEffect(() => {
        //     if (user && user.id) {
        //         window.Echo.private(`notifs.${user.id}`).listen("SentNotif", (e) => {
        //             setNotifCount(prevState => (prevState ? [...prevState, e.notifs] : [e.notifs]));
        //         });
        //     }
        // }, [user]);
        
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        return (
            <Badge
                badgeContent={notifs}
                classes={{ badge: classes.badge }}
                color="primary"
            >
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {user && children && children.length > 0 && (
                        <Badge
                            badgeContent={notifs}
                            classes={{ badge: classes.badge }}
                            color="primary"
                        >
                            <MenuItem onClick={() => setValue(2)}>
                                Friend Requests
                            </MenuItem>
                        </Badge>
                    )}
                    <MenuItem
                        onClick={() => {
                            window.localStorage.removeItem("JWT_TOKEN");
                            window.localStorage.removeItem("USER");
                            window.localStorage.removeItem("CHILDREN");
                            window.localStorage.removeItem("SETTINGS");
                            window.location = `/`;
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    const panels = [
        <Home showNotification={showNotification} user={user} theme={theme} />,
        <LifeTopics user={user} theme={theme} />,
        <XCPals user={user} theme={theme} showNotification={showNotification} />,
        <Journal user={user} theme={theme} />,
        <OnlineResources user={user} theme={theme} />,
        <ParentsPortal user={user} theme={theme} />
    ];
    
    if (isLoading || propsIsLoading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
                <p>Loading dashboard...</p>
            </div>
        );
    }
    
    if (!user) {
        return (
            <div className={classes.loadingContainer}>
                <p>Error: User data not available. Please try refreshing the page.</p>
            </div>
        );
    }
    
    console.log("🚀 ~ file: Dashboard.jsx:238 ~ Dashboard ~ user:", user);
    return (
        <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={9}>
                    <img
                        style={{
                            marginLeft: "10px",
                            width: "256px",
                            height: "76px",
                        }}
                        src={user.gender === "F" ? "/icons/XcBrgPalGirls_sideways_02.png" : "/icons/XcBrgPalBoys_sideways_02.png"}
                        alt={user.gender === "F" ? "XcBrgPalGirls" : "XcBrgPalBoys"}
                    />
                </Grid>
                
                <Grid item xs={1}>
                    <Badge
                        badgeContent={messageCount}
                        classes={{ badge: classes.notifBadge }}
                        color="primary"
                        style={{ justifyContent: "flex-end" }}
                    >
                        {settings && settings?.chat_allowed === 1 ? (
                            <img
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    paddingLeft: "76px",
                                    paddingTop: "20px",
                                    cursor: "pointer"
                                }}
                                onClick={() => history.push("/messenger")}
                                src="/icons/chat.png"
                                alt="Chat"
                            />
                        ) : null}
                    </Badge>
                </Grid>
                <Grid
                    item
                    className={classes.topNav}
                    justifyContent="right"
                    xs={1}
                    spacing={3}
                >
                    <BrAvatar
                        user={user}
                        theme={theme}
                        label={user.name}
                        onClick={() => history.push("/user/" + user.id)}
                        avatarStyles={{cursor: "pointer"}}
                    />
                </Grid>
                <Grid item xs={1}>
                    <SimpleMenu />
                </Grid>
                <NavTabs
                    user={user}
                    setValue={handleTabChange}
                    value={value}
                    panels={panels}
                    theme={theme}
                />
            </Grid>
            
            {panels[initialTab ? initialTab : value]}
        </div>
    );
};