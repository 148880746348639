import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonBrg: {
        background: "transparent",
        fontSize: 22,
        fontFamily: 'GoodUnicorn',
        marginTop: 10,
        fontWeight: 600,
        border: "none",
        boxShadow: "none",
    }
}));

export default function DialogSelect(props) {
    const { user } = props;
    
    const { items, heading, defaultLabel } = props.DTO;
    const dialogItems = Object.entries(items);
    
    const classes = useStyles();
    
    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState('');
    
    const handleChange = (event) => {
        setItem(event.target.value || '');
    };
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        if (item.length) {
            props.saveJournalStyle(item);
        }
        setOpen(false);
    };
    
    return (
        <div>
            <Button className={classes.buttonBrg} style={user.gender === "M" ? {
                    color: "#3344cc",
                } : {
                    color: "#eb4c8a"
                }} 
                onClick={handleClickOpen}>{heading}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{heading}</DialogTitle>
                <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">{defaultLabel}</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={item}
                                onChange={handleChange}
                                input={<Input />}
                            >
                                <MenuItem value="">
                                    <em>default</em>
                                </MenuItem>
                                {
                                    dialogItems.map((item) => {
                                        return (<MenuItem value={item[1].value}>
                                            <em>{item[1].name}</em>
                                        </MenuItem>);
                                    })
                                }
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}