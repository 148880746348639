import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";

import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "../Input/BootstrapInput";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Select from "@material-ui/core/Select";

export default function TermsAndConditions(props) {
    const useStyles = makeStyles((theme) => ({
        button: {
            width: "80px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px",
        },
        root: {
            minHeight: 500,
            minWidth: 258,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: "#bbb", //'#eb4c8a'
        },
        margin: {
            margin: theme.spacing(1),
        },
        modal: {
            position: "absolute",
            width: 500,
            maxHeight: 500,
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: "20%",
            left: "30%",
            overflow: "scroll",
        },

        divider: {
            margin: 10,
        },
    }));
    const classes = useStyles();

    const modalBody = (
        <div className={classes.modal}>
            <div class="WordSection1">
                <p class="MsoNormal">XcBrgPal LLC Terms and Conditions</p>

                <p class="MsoNormal">Effective date 9/1/2021</p>

                <p class="MsoNormal">1.Terms of Service</p>

                <p class="MsoNormal">
                    By choosing to visit the XcBrgPal LLC,website, &nbsp;You
                    agree that these terms and conditions (the “Terms”) govern
                    your use of XcBrgPal website and/or app&nbsp; (the “XcBrgPal
                    Platform”), except where we expressly state that separate
                    terms (and not these) apply. These Terms may be amended from
                    time to time.&nbsp; Please read the information below
                    carefully. If You are a parent or guardian and You provide
                    your consent to your child's (12yrs and below in the United
                    States of America or 15yrs and below in the European Union)
                    use of the XcBrgPal Platform, You agree to be bound by these
                    Terms in respect to their use of the XcBrgPal Platform. By
                    your continued access or use of the platform, You signify
                    your agreement to be legally bound by the terms set forth
                    the Terms. If You do not agree to the terms of this
                    agreement, promptly exit this page and stop accessing the
                    Services.
                </p>

                <p class="MsoNormal">2. Description of Services</p>

                <p class="MsoNormal">
                    XcBrgPal LLC (the “Company”) facilitates social media
                    interaction services to children (the “Services”). Our aim
                    is to cross-culturally connect children and have them
                    positively share their social life experiences as guided by
                    mentors. However, for accessing the Services, the child
                    (12yrs and below in the United States of America or 15yrs
                    and below in the European Union) or “You” (the parent,
                    guardian and or authorized employee of participating
                    institution) may have to create an account and disclose
                    personal information. For more information on the collection
                    and use of personal information please see our privacy
                    policy here &lt;xcbrgpal.com/privacy&gt;.&nbsp;{" "}
                </p>

                <p class="MsoNormal">3. License to Use</p>

                <p class="MsoNormal">
                    The Company hereby grants You (parent, guardian and or
                    authorized employee of a participating institution) and the
                    child (12yrs and below in the United States of America or
                    15yrs and below in the European Union), the limited right to
                    access, view and use the XcBrgPal Platform only for the
                    purposes of accessing, viewing, posting or submitting user
                    material, using the embedded link function, placing store
                    orders or for accessing information, applications and the
                    Services. The Company reserves the right to suspend or deny,
                    in its sole discretion, You and your child’s access to all
                    or any portion of the XcBrgPal Platform. This license is
                    limited to personal and non-commercial uses by You and your
                    child. Any rights not expressly granted to You herein are
                    reserved to the Company.
                </p>

                <p class="MsoNormal">4. Intellectual Property Rights</p>

                <p class="MsoNormal">
                    The Company retains all rights (including copyrights,
                    trademarks, patents, designs, logos, trade-dress,
                    trade-secrets, know-how as well as any other intellectual
                    property right) in relation to all information provided on
                    or via this XcBrgPal Platform (including but not limited to
                    the topics shared, all texts, graphics, photos,
                    illustrations, apps and logos). You shall not copy,
                    download, publish, distribute, or reproduce any of the
                    information contained on this XcBrgPal Platform or social
                    media in any form without the prior written consent of the
                    Company.
                </p>

                <p class="MsoNormal">&nbsp;</p>

                <p class="MsoNormal">&nbsp;</p>

                <p class="MsoNormal">&nbsp;</p>

                <p class="MsoNormal">5. Linking</p>

                <p class="MsoNormal">
                    You or the child may establish a link to one or more
                    site(s), provided that:
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    the link is legal and not detrimental or damaging to and/or
                    does not take unfair advantage of our reputation or
                    business. Such linking is not for advertising or promotional
                    purposes (unless the Company has expressly agreed to it).
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    the link is not from any website which promotes any
                    political, or promotes or depicts intolerance, hatred,
                    bullying, discrimination, violence, pornography or illegal
                    activity.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    the link does not falsely or misleadingly imply or suggest
                    that We endorse, approve of or are associated with the
                    linked website, its web pages or any of its contents; and
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    framing of any site on any other website is not allowed and
                    You must not provide access to the site or part of it under
                    any other URL.
                </p>

                <p class="MsoNormal">
                    The Company may withdraw your or the child’s right to link
                    to any site without notice and at any time (acting in our
                    sole discretion). Where any site and/or application contain
                    links to other websites, web pages, resources, or mobile
                    services which are proprietary to third-parties, other
                    users, advertisers or sponsors, such websites, web pages,
                    resources and mobile services are provided for your
                    information only and You access them at your own risk. The
                    Company will not be held liable or responsible for the
                    content or operation of third-party websites, web pages,
                    resources or mobile services.
                </p>

                <p class="MsoNormal">6. Payment and Refund</p>

                <p class="MsoNormal">
                    The XcBrgPal Platform is a paid service and the payments
                    made by You shall be according to the plans opted by You
                    through the Platform. All payments by users shall be through
                    the payment mechanism put in place by Us. Users have been
                    given various options to choose and proceed with the payment
                    for the Services via payment mechanisms including but not
                    limited to card payment facilities, EMI payment, online bank
                    transfer and wallet payment. With reference to the payment
                    gateway mechanisms are governed by the terms and conditions
                    of the third-party providers as listed on the XcBrgPal
                    Platform and the user agrees to be bound by those Terms.
                </p>

                <p class="MsoNormal">
                    Valid credit / debit/ cash card/ online bank transfers and
                    other payment instruments are processed using and will be
                    governed by the terms of the credit card payment gateway or
                    appropriate payment system infrastructure. The Company shall
                    not be liable for any unauthorized use, fraud, payment
                    refunds, lost amount etc. in the transaction. The amount of
                    refund payable shall only be limited to the amount paid by
                    You for Services which were not rendered You by the Company.
                    In case of refund of EMI transactions, You will be charged
                    interest as per bank’s regulations. Any charges related to
                    app publishing (e.g., charges levied by app store, play
                    store) will be incurred by You.
                </p>

                <p class="MsoNormal">
                    Any free trials provided on the platform XcBrgPal Platform,
                    shall also be governed by these Terms.
                </p>

                <p class="MsoNormal">7. User Content</p>

                <p class="MsoNormal">
                    The Company offers You and your child the opportunity to
                    submit, post, display, transmit, perform, publish,
                    distribute or broadcast content and materials, including,
                    without limitation, articles, commentaries, photographs,
                    text, music, video, audio recordings, computer graphics,
                    pictures, data, questions, comments, suggestions or
                    personally identifiable information. The Company may remove
                    content at its discretion. Your child may choose to write
                    and post reviews on the XcBrgPal Platform. We do not
                    encourage your child to post any personal information on the
                    XcBrgPal Platform and the ill effects of such revelation
                    shall be borne by You and your child.&nbsp; However, You can
                    request the Company via e-mails to delete any such
                    information posted by the child. For the purpose of
                    verification, the Company will review and scrutinize such
                    emails before responding.
                </p>

                <p class="MsoNormal">
                    You warrant and represent that your content, and the content
                    of any website from which You include a link to any site, or
                    to which You post a link from a site, will not be
                    inappropriate. Without limitation, content (and the content
                    of third-party websites) may be considered inappropriate if:
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is misleading in any way, and/or it gives a false
                    impression as to its origins or approvals.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is defamatory, plagiarized (including plagiarism from
                    your own work), abusive, malicious, threatening, false,
                    misleading, offensive, insulting, discriminatory, profane,
                    harassing, racist, sexist, indecent, obscene, pornographic,
                    hateful or it advocates violence.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is in breach of confidentiality or another person’s
                    privacy or other rights, or of any duty owed by You.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it prejudices any active or pending legal proceedings of
                    which You are aware.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it contains accusations of impropriety or personal criticism
                    of our personnel, editors, or reviewers.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it infringes any intellectual property rights proprietary to
                    the Company or any third party.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is technically harmful (including content containing,
                    without limitation, computer viruses, logic bombs, trojan
                    horses, worms, harmful components, corrupted data or other
                    malicious software, harmful data or conduct and/or contains
                    any other element which is intended to harm the Company or
                    any third party, or to carry out or facilitate any
                    fraudulent or dishonest transaction).
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it advertises or promotes any product or service or makes
                    any requests for donations or financial support.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is spam or junk content.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it impersonates another person or otherwise misrepresents
                    your identity, affiliation or status.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it would be considered a criminal or statutory offense in
                    any jurisdiction, or gives rise to civil liability, or is
                    otherwise unlawful; and/or
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    it is in breach of these Terms and/or of any additional
                    terms from third-party websites.
                </p>

                <p class="MsoNormal">
                    The Company will not be held responsible or liable for any
                    of the user content provided by You and your child on the
                    XcBrgPal Platform.&nbsp; You and your child must not attempt
                    to avoid or undermine any protections that the Company may
                    put in place for the security and operation of the website
                    and XcBrgPal Platform.
                </p>

                <p class="MsoNormal">
                    Video/ sound recordings of social experiences conducted
                    containing footage of You and/or recording of any video of
                    your Child are all part of the Services of the XcBrgPal
                    platform.
                </p>

                <p class="MsoNormal">8. Intended Purpose of Use</p>

                <p class="MsoNormal">
                    Any application, code or content created using the XcBrgPal
                    Platform is intended to be used solely as prototypes and for
                    evaluation of validity and practicability of ideas. All such
                    applications, codes and/or content may be solely created for
                    social sharing and gathering feedback by the children and
                    are in no way fit for or meant to be used for any commercial
                    use. Any other use of such apps, codes, or content, other
                    than the use specified herein is prohibited by the Company
                    and the Company shall not be liable for the same and You and
                    your child shall indemnify the Company for any loss, claims
                    or damages suffered by the Company in this regard.
                </p>

                <p class="MsoNormal">9. Web App Usage</p>

                <p class="MsoNormal">
                    <span style={{ color: "black" }}>
                        The Company also provides a web app for You or Your
                        child. Please refer to the terms associated with this
                        app when downloading it.
                    </span>
                </p>

                <p class="MsoNormal">10. User Communication</p>

                <p class="MsoNormal">
                    You hereby explicitly consent to receive emails from the
                    Company, for the purpose of providing alerts and information
                    related to Services.
                </p>

                <p class="MsoNormal">11. Rule of Conduct</p>

                <p class="MsoNormal">
                    Users must comply with the laws that apply to You in the
                    location that You access the Company’s Services from. If any
                    laws applicable to You restrict or prohibit You from using
                    Services of the Company. You must comply with those legal
                    restrictions or, if applicable, stop accessing and/or using
                    the Services of the Company. You promise that all the
                    information You provide to the Company on accessing and/or
                    using the Services of the Company is and shall remain true,
                    accurate to the best of our abilities
                </p>

                <p class="MsoNormal">
                    Notwithstanding any other provision of these Terms, You
                    agree and undertake to not;
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Hack, attempt to hack, modify, adapt, merge, translate,
                    decompile, disassemble, reverse engineer or create
                    derivative works out of the Service or any part of them
                    (save to the extent which expressly cannot be prohibited in
                    accordance with the applicable mandatory law in your
                    jurisdiction);
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Remove, disable, modify, add to or tamper with any program
                    code or data, copyright, trademark or other proprietary
                    notices and legends contained on or in the Services.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Create software which mimics any data or functionality in
                    the Services.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Use or deal in the Service except as permitted by these
                    Terms.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Include contact details intended to enable communication
                    outside of the Service, in any communication.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Use your access to the Service, or information gathered from
                    it, for the sending of unsolicited bulk and non-bulk emails.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Make any public, business or commercial use of the Services
                    or any part of them.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Provide hypertext links, URL links, graphic links,
                    hyperlinks or other direct connection for profit or gain to
                    the Service without prior written permission of the Company.
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Make the Service or any part of it available to any third
                    party (please note this does not stop You from fairly and
                    honestly providing links to the Platform or showing to other
                    people).
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Use or process the Service or any part of them unfairly or
                    for any illegal or immoral purpose; or
                </p>

                <p class="MsoNormal">
                    •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Delete or obscure any copyright or other proprietary notice
                    on the Services.
                </p>

                <p class="MsoNormal">
                    The Service may only be accessed and used via authorized
                    servers. You must take no action to bypass authorized
                    servers and/or use third party software to modify any aspect
                    of the Service.,{" "}
                </p>

                <p class="MsoNormal">12. Liability Limitations </p>

                <p class="MsoNormal">
                    You are held personally liable for any violation of a third
                    party's rights by You and your child. You agree to reimburse
                    the Company for all damages resulting from the culpable
                    non-observance of the obligations of these Terms. Users
                    release the Company from all eligible claims that other
                    users or third parties may file against the Company due to a
                    violation of their rights by content posted by the user or
                    due to a violation of other obligations. You shall assume
                    the costs of the Company legal defense, including all court
                    and legal fees. This condition does not apply if the Users
                    are not responsible for the infringement.
                </p>

                <p class="MsoNormal">
                    The Company ensures that the information on the Platform is
                    acceptable and safe for children to use. You agree and
                    understand that the Company does not state or claims any
                    warranty for the quality of Services, for the online
                    resources, or for the appointed mentors who share life
                    topics
                </p>

                <p class="MsoNormal">
                    The Company undertakes the scrutiny of the social topics
                    delivered by appointed mentors. At&nbsp;&nbsp; any instance
                    of sharing social topics, if a mentor uses any sexual or
                    abusive language or depicts any forced sexual acts, or
                    pornographic images, then in such cases, please immediately
                    inform the Company at the contact provided below in Clause
                    21. The Company will make all efforts to take any and all
                    necessary actions as per the applicable law. However,
                    Company explicitly disclaims any liability or responsibility
                    in the event of such a circumstance.&nbsp;{" "}
                </p>

                <p class="MsoNormal">
                    The Company will not be held responsible for any unethical,
                    illegal acts performed by the child on the XcBrgPal
                    Platform, and it shall be your responsibility to closely
                    monitor the activities of your child while accessing the
                    XcBrgPal Platform.
                </p>

                <p class="MsoNormal">
                    In no event shall the Company be liable for any direct,
                    indirect, incidental, special or consequential damages, or
                    damages for loss of profits, revenue, data, or data use,
                    incurred by You or any third-party, whether in an action in
                    contract or tort, arising from your access to, or use of,
                    the Platform or any content provided on or through the
                    Platform.
                </p>

                <p class="MsoNormal">
                    The Company facilitates social shared topics on an "as is"
                    basis and is liable only to provide its Services with
                    reasonable skill and care.
                </p>

                <p class="MsoNormal">
                    The Company's liability for any and all claims in the
                    aggregate, arising out of or related to your use of the
                    Platform, shall not under any circumstances exceed the
                    amounts actually paid by You to the Company for its
                    Services.
                </p>

                <p class="MsoNormal">
                    The Company does not warrant that the operation of the
                    Services will be uninterrupted or error-free. The Company
                    will not be liable in any amount for failure to perform any
                    obligation under this agreement if such failure is caused by
                    the occurrence of any unforeseen contingency beyond the
                    reasonable control of the Company including internet
                    outages, communications outages, fire, flood, war or act of
                    God.
                </p>

                <p class="MsoNormal">
                    Except as provided above there are no other warranties,
                    conditions or other terms and conditions, express or
                    implied, statutory or otherwise, and all such terms and
                    conditions are hereby excluded to the maximum extent
                    permitted by law.
                </p>

                <p class="MsoNormal">
                    You agree that in relation to your use of the Services You
                    will not in any way conduct yourself in a manner which is
                    unlawful, or which gives rise to civil or criminal
                    liability, or which might call the Company or the Services
                    into disrepute. You agree that You are and shall remain
                    responsible for maintaining the confidentiality of your
                    password and username and for all activities that occur
                    under your account.
                </p>

                <p class="MsoNormal">13. Third- Party Permission</p>

                <p class="MsoNormal">
                    The Company has subscribed to various third-party service
                    namely Amazon AWS Rekognition for content moderation and
                    Sentry for error/bug moderation. You agree and acknowledge
                    that, while accepting these Terms, You explicitly grant
                    permission to these service providers to use your
                    information solely for this purpose. In the event of any
                    dispute between the third party and You, the Company shall
                    not be held liable in any matter whatsoever.&nbsp;{" "}
                </p>

                <p class="MsoNormal">14. Amendments to Terms</p>

                <p class="MsoNormal">
                    XcBrgPal LLC (Company) retains the right to modify these
                    Terms given any of the following events: (a) in the event of
                    any amendments made due to legal or regulatory requirements,
                    (b) because of technical necessity, (c) in order to maintain
                    XcBrgPal LLC (Company) operations, (d) in the event of a
                    change in market conditions, or (e) for the benefit of the
                    user.
                </p>

                <p class="MsoNormal">
                    No amendment will take place if such amendment would
                    substantially disrupt the contractual balance between the
                    parties.&nbsp;&nbsp; Users will be informed of any
                    amendments to the general Terms via the Platform or via
                    notice by email or in writing and have an opportunity to
                    close and delete their accounts.{" "}
                </p>

                <p class="MsoNormal">15. Termination</p>

                <p class="MsoNormal">
                    Without limiting any other rights that the Company may have,
                    the Company may remove, restrict, cancel or suspend access
                    to and/or use of the service and any part of it, if the
                    Company considers (in the sole discretion of the Company
                    that You have breached any of these Terms.
                </p>

                <p class="MsoNormal">
                    You may also terminate your agreement with the Company by
                    ceasing to access the Service, deleting all copies of the
                    Service or part thereof within your control. Termination
                    shall not affect any rights or remedies of either party,
                    which have accrued up to the time of termination.
                </p>

                <p class="MsoNormal">16. Indemnity&nbsp; </p>

                <p class="MsoNormal">
                    You agree to indemnify and hold us harmless, our
                    contractors, and our licensors, and respective directors,
                    officers, employees and agents from and against any all
                    claims and expenses, including attorneys’ fees, arising out
                    of your use of the Services and/or the Platform, including
                    but not limited to your breach of any of these Terms to the
                    extent allowed by law.{" "}
                </p>

                <p class="MsoNormal">17. Severability</p>

                <p class="MsoNormal">
                    If any provision of this these Terms is held to be invalid
                    or unenforceable by any court of competent jurisdiction,
                    such provision will be deemed modified so as to be valid and
                    enforceable to the greatest extent possible under applicable
                    law, and the validity of the remaining provisions hereof
                    shall not be affected thereby.
                </p>

                <p class="MsoNormal">18. Assignment</p>

                <p class="MsoNormal">
                    Any rights and licenses granted hereunder shall not be
                    transferred or assigned by You. Any attempted transfer or
                    assignment in violation hereof shall be considered as null
                    and void.
                </p>

                <p class="MsoNormal">19. Waiver</p>

                <p class="MsoNormal">
                    No waiver of any terms of this agreement shall be treated as
                    a further or continuing waiver of such term or any other
                    term and the Company’s failure to assert any right or
                    provision under this agreement shall not constitute a waiver
                    of such right or provision.
                </p>

                <p class="MsoNormal">
                    20. Governing Laws and Jurisdiction&nbsp;{" "}
                </p>

                <p class="MsoNormal">
                    You agree that all matters relating to your access to or use
                    of the XcBrgPal website and app (Platform) including all
                    disputes, and all claims involving the Company or its
                    employees, contractors, officers, directors,
                    telecommunication providers, and content providers including
                    all disputes, will be governed by the laws of the United
                    States and by the laws of the State of California without
                    regard to its conflicts of law’s provisions. You agree to
                    the personal jurisdiction by and venue in the state and
                    federal courts in Orange County, California, and waive any
                    objection to such jurisdiction or venue. These Terms are
                    governed by the internal substantive laws of the State of
                    California, without respect to its conflict of laws
                    principles.{" "}
                </p>

                <p class="MsoNormal">
                    Any claims arising in connection with your use of the
                    XcBrgPal website and/or app must be brought within one (1)
                    year of the date of the event giving rise to such action
                    occurred, or such claim is barred. Remedies under these
                    Terms are exclusive and are limited to those expressly
                    provided for in these Terms. No recovery may be sought or
                    received for damages other than out-of-pocket expenses,
                    except that the prevailing party will be entitled to costs
                    and attorney’s fees. In the event of any controversy or
                    dispute between XcBrgPal LLC and You arising out of or in
                    connection with your use of the website and app, You and
                    XcBrgPal LLC agree to attempt, promptly and in good faith,
                    to resolve any such dispute. If we are unable to resolve any
                    such dispute within a reasonable time (not to exceed thirty
                    (30) days), then either party may submit such controversy or
                    dispute to mediation prior to pursuing any action in state
                    or federal courts. If the dispute cannot be resolved through
                    mediation, then the parties shall be free to pursue any
                    right or remedy available to them under applicable law.{" "}
                </p>

                <p class="MsoNormal">&nbsp;</p>

                <p class="MsoNormal">21. Contact</p>

                <p class="MsoNormal">
                    If You have any questions about these Terms, please contact
                    us by email or postal mail on the following address:
                </p>

                <p class="MsoNormal">Name: The Administrator</p>

                <p class="MsoNormal">E-mail id: info@xcbrgpal.com</p>

                <p class="MsoNormal">
                    Postal Address: PO Box 24552 Raymond Way #636 Lake Forest,
                    CA 92609 - 0636
                </p>
            </div>
        </div>
    );

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {modalBody}
        </Modal>
    );
}
