import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { Dashboard } from "../Dashboard";
import BrAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Messenger from "./Messenger";

const { REACT_APP_API_URL } = process.env;


export default function MessengerPage(props) {
    const { user, theme } = props;
    const [messages, setMessages] = useState([]);
    const [selectedUser, setSelectedUser] = useState(user);
    const [edit, setEdit] = useState(localStorage.getItem("USER")  || {});
    
    const [message, setMessage] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    
    console.log("🚀 ~ file: MessengerPage.jsx:23 ~ MessengerPage ~ user:", user);
    
    const useStyles = makeStyles({
        root: {
            minHeight: 500,
            maxHeight: 500,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        conversation: {
            width: "100%",
            overflow: "scroll",
            height: "332px",
            paddingInlineStart: 0,
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: theme.primaryColor,
        },
        avatar: {
            marginLeft: "30%",
            marginTop: "40%",
            width: "20%",
            height: "20%",
        },
        pos: {
            marginBottom: 12,
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        chatGrid: {
            marginLeft: "40px",
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)",
        },
        imagesCard: {
            backgroundColor: "#d6f1fe",
        },
        images: {
            margin: "4px",
        },
        toggle: {
            margin: "10%",
            borderRadius: "15px",
            backgroundColor: "#fffefe",
            textAlign: "center",
        },
        self: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        selfChip: {
            background: "linear-gradient(90deg, #ffa0a4 20%, #eb4c8a 80%)",
            color: "white",
        },
        recipientChip: {
            background: "floralwhite",
        }
    });
    
    const classes = useStyles();
    
    const chipCustom = makeStyles({
        label: { whiteSpace: "normal", overflow: "auto", margin: "5px" },
    })();
    
    useEffect(async () => {
    if (selectedUser && selectedUser.id) {
        console.log("🚀 ~ file: MessengerPage.jsx:107 ~ useEffect ~ selectedUser: ", selectedUser);
        const result = await axios(
            `${REACT_APP_API_URL}/messages/${user.id}/${selectedUser.id}`
        );
        if (result.status === 200) {
            setMessages(result.data);
            setMessage("");
            const update_messages = result.data.filter(
                (message) => message.seen !== 1 && message.recipient_id === user.id
            );
            if (update_messages.length) {
                const seen_messages = await axios(
                `${REACT_APP_API_URL}/see_messages/${selectedUser.id}`,
                { method: "POST", data: { messages: update_messages } }
                );
            }
        }
    }
    }, [selectedUser]);
    
    const sendMessage = async () => {
        if(edit.id){
            const result = await axios(
                `${REACT_APP_API_URL}/messages/${edit.id}`,
                { method: "PUT", data: { content: message } }
            );
            if (result.status === 200) {
                messages.map( (item) => { if(item.id === result.data.id){ item.content = result.data.content; } return item; });
                setMessage("");
                setCharacterCount(0);
                setEdit({});
                setMessages([...messages]);
            }
        } else {
            const result = await axios(
                `${REACT_APP_API_URL}/send_message/${user.id}`,
                { method: "POST", data: { content: message } }
            );
            if (result.status === 201) {
                setMessages([...messages, { sender_id: user.id, content: message, id:result.data.id, created_at:result.data.created_at }]);
                setMessage("");
                setCharacterCount(0);
                setFirstMessages({
                    ...firstMessages,
                    [selectedUser.id]: message
                });
            }
        }
    };
    
    const handleMessageInput = (e) => {
        if (e.keyCode == 13) {
            return sendMessage();
        }
        setMessage(e.target.value.slice(0, 240));
        setCharacterCount(e.target.value.slice(0, 240).length);
    };
    
    const handleEdit = (message) => {
        setMessage(message.content);
        setEdit(message);
    };
    
    const handleRemove = async (message) => {
        console.log("Message: ", message);
        const result = await axios(
            `${REACT_APP_API_URL}/messages/${message.id}`,
            { method: "Delete", data: { content: message } }
        );
        setMessages(messages.filter(item => item.id !== message.id));
    }
    
    const [firstMessages, setFirstMessages] = useState({});
    
    const XCPalRows = useMemo(
        () => (props) => {
            const { friends, selectedUser, setSelectedUser, classes, theme } = props;
            return friends.map((pal) => (
                <XCPalRow
                    user={pal}
                    selectedUser={selectedUser}
                    firstMessages={firstMessages}
                    setSelectedUser={setSelectedUser}
                    classes={classes}
                    theme={theme}
                />
            ));
        },
        [selectedUser, firstMessages]
    );
    
    const XCPalRow = useMemo(
        () => (props) => {
                const {
                user,
                firstMessages,
                setSelectedUser,
                selectedUser,
                classes,
                theme
            } = props;
            
            return (
                <Paper
                    elevation={0}
                    style={
                    selectedUser && selectedUser.id === user.id
                        ? { backgroundColor: "#f1f1f1" }
                        : {}
                    }
                    className={classes.palrow}
                    onClick={() => setSelectedUser(user)}
                >
                    <Grid container spacing={2}>
                        <Grid xs={3}>
                            <BrAvatar user={user} classes={classes} theme={theme} />
                        </Grid>
                        <Grid xs={9}>
                            <Typography className={classes.xcName}>
                            {" "}
                            {props.user.name}{" "}
                            </Typography>
                            <Typography
                            style={{
                                maxHeight: "100px",
                                overflow: "auto",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            >
                            {" "}
                            {firstMessages[user.id]
                                ? firstMessages[user.id].content
                                : ""}{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            );
        },
        [classes, firstMessages, selectedUser]
    );
    
    const [friends, setFriends] = useState([]);
    
    useEffect(async () => {
        const result = await axios(`${REACT_APP_API_URL}/friends/${user.id}`);
        setFriends(result.data);
        
        const result_2 = await axios(
            `${REACT_APP_API_URL}/first_messages/${user.id}`
        );
        
        setSelectedUser(
            result.data.find(
            (friend) => friend.id == window.location.hash.slice(1)
            ) ||
            result.data[0] ||
            {}
        );
        let firstMessages = {};
        if (result.data) {
            result.data.map((friend) => {
                firstMessages[friend.id] = result_2.data.find(
                    (message) =>
                    message.recipient_id == friend.id || message.sender_id == friend.id
                );
            });
            setFirstMessages(firstMessages);
        }
        window.Echo.private(`messages.${user.id}`).listen("SentMessage", (e) => {
            setMessages((prevState) => [...prevState, e.message]);
        });
    }, []);
    
    return (
        <div>
            <Dashboard initialTab={6} user={props.user} theme={theme} />
                <div>
                <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid className={classes.title} item xs={11}>
                        Chat
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3} className={classes.palGrid}>
                        <Card className={classes.root}>
                            <Paper className={classes.toggle}></Paper>
                            
                            <XCPalRows
                                friends={friends}
                                firstMessages={firstMessages}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                classes={classes}
                                theme={theme}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={6} className={classes.chatGrid}>
                        <Messenger
                            characterCount={characterCount}
                            messages={messages}
                            message={message}
                            selectedUser={selectedUser}
                            sendMessage={sendMessage}
                            handleMessageInput={handleMessageInput}
                            handleEdit = { handleEdit }
                            handleRemove = { handleRemove }
                            edit={edit}
                            
                            theme={theme}
                            user={user}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};