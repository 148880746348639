import { useEffect, useState } from "react";
import BrAvatar from "./Avatar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import BootstrapInput from "../Input/BootstrapInput";
import AlertDialogSlide from "../Input/TransitionDialog";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";


const { REACT_APP_API_URL } = process.env;


export default function LifeTopicPost(props) {
    const useStyles = makeStyles((theme) => ({
        badge: {
            right: 10,
            top: 11,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px"
        },
        notif_badge: {
            right: 1,
            top: 15,
            border: `2px solid ${theme.primaryColor}`,
            padding: "0 4px"
        },
        root: {
            flexGrow: 1
        },
        topNav: {
            height: "75px",
            width: "100%"
        },
        control: {
            padding: theme.spacing(2),
        }
    }));
    
    const classes = useStyles();
    
    const history = useHistory();
    
    const token = window.localStorage.getItem("JWT_TOKEN");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    document.cookie = `token=${token}`;
    
    const { user, theme, post, removePost, showNotification } = props;
    console.log("🚀 ~ file: LifeTopicPost.jsx:58 ~ LifeTopicPost ~ post:", post);
    
    const handleCommentChange = (e) => {
        if (e.target.value.slice(-1) === "\n") {
            return;
        } else {
            setComment({id:comment.id, content:e.target.value});
        }
    };
    
    const likePost = async (e) => {
        if(user.isParent){
            return;
        }
        if (likes.find((like) => like.user_id === user.id)) {
            await axios(`${REACT_APP_API_URL}/life_topic_posts/like/${post.id}`, {
                method: "DELETE",
                data: comment
            });
            setLikes([...likes.filter((like) => like.user_id !== user.id)]);
        } else {
            await axios(`${REACT_APP_API_URL}/life_topic_posts/like/${post.id}`, {
                method: "POST",
                data: comment
            });
            setLikes([...likes, { user_id: user.id, life_topic_post_id: post.id }]);
        }
    };
    
    const removePostAsync = async (e) => {
        await axios(`${REACT_APP_API_URL}/life_topic_posts/remove/${post.id}`, {
            method: "DELETE",
            data: comment
        });
        removePost(post.id);
    };
    
    const approvePostAsync = async (e) => {
        await axios(`${REACT_APP_API_URL}/life_topic_posts/approve/${post.id}`, {
            method: "POST"
        });
        setApproved(true);
        showNotification("post approved", "success");
        // approvePost(post.id);
    };
    
    const approveCommentAsync = async (comment) => {
        await axios(
            `${REACT_APP_API_URL}/life_topic_posts/comment/approve/${comment.id}`,
            { method: "POST" }
        );
        setApproved(true);
        showNotification("comment approved", "success");
        // approvePost(post.id);
    };
    
    const removeCommentAsync = async (comment) => {
        await axios(`${REACT_APP_API_URL}/life_topic_posts/comment/${comment.id}`, {
            method: "DELETE",
            data: { content: comment },
        });
        removeComment(comment.id);
    };
    
    const setCommentEditing = (comment) => {
        removeComment(comment.id);
        setComment(comment);
    }
    const setPostEditing = () => {
        setEditing(!editing);
        //  removePostText(post.id);
        //  setPostText(post);
    }
    
    const updatePost = async () => {
        axios
            .put(`${REACT_APP_API_URL}/life_topic_posts/` + post.id, {message:message})
            .then((result) => {
                showNotification("success", "success");
                setEditing(!editing);
            })
            .catch(({ message, response }) => {
                showNotification(response.data.message, "error");
            });
    };
    
    const awardPost = async (e) => {
        if (!user.is_mentor) {
            return;
        }
        if (awards.filter((award) => award.user_id == user.id).length === 0) {
            const new_award = await axios(
                `${REACT_APP_API_URL}/life_topic_posts/award/${post.id}`,
                { method: "POST" }
            );
            setAwards([...awards, new_award.data]);
        }
    };
    
    const handleKeyDown = async (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey) {
                setComment({content:comment + "\n"});
            } else {
                let attemptComment = await axios(
                    `${REACT_APP_API_URL}/life_topic_posts/comment/${post.id}`,
                    { method: "POST", data: { comment } }
                );
                if (attemptComment.status === 400) {
                    showNotification("your message was rejected", "warning");
                    return;
                }
                setComments([...comments, { user, content: comment.content, id:attemptComment.data.id }]);
                setComment({content: ""});
            }
        }
    };
    
    const SimpleMenu = (props) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [notifs, setNotifCount] = useState(null);
        const { comment } = props;
        
        useEffect(async () => {
            if (user.parent) {
                const result = await axios(
                    `${REACT_APP_API_URL}/pending_friends/${user.id}`
                );
                setNotifCount(result.data.length);
                window.Echo.private(`notifications.${user.parent.id}`).listen(
                    "SentNotification",
                    (e) => {
                        console.log(e);
                        setNotifCount(e.data.count);
                    }
                );
            }
        }, []);
        
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };
        
        window.Echo.private(`notifs.${user.id}`).listen("SentNotif", (e) => {
            setNotifCount((prevState) => [...prevState, e.notifs]);
        });
        
        return (
            <Badge
                classes={{ badge: classes.badge }}
                color="primary"
            >
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ color: theme.primaryColor }}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={(e) => setCommentEditing(comment)}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => removeCommentAsync(comment)}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </Badge>
        );
    };
    
    const removeComment = (comment_id) => {
        setComments([...comments.filter((comment) => {
            return comment_id !== comment.id;
        })]);
    };
    
    const [comment, setComment] = useState({content:''});
    const [comments, setComments] = useState([]);
    const [likes, setLikes] = useState([]);
    const [awards, setAwards] = useState(post.awards);
    const [approved, setApproved] = useState(post.approved);
    const [message, setMessage] = useState(post.message);
    const [editing, setEditing] = useState(false);
    
    console.log("🚀 ~ file: LifeTopicPost.jsx:259 ~ LifeTopicPost ~ awards:", awards);
    
    useEffect(() => {
        setComments(post.comments);
        setLikes(post.likes);
    }, [post]);
    
    return (
        <>
        <Grid xs={4} style={{ zIndex: 1 }}>
            <Card style={{ marginTop: "100px", width: "100%" }}>
                <Grid container>
                    <Grid item xs={9}>
                        <span
                            style={{
                                color: theme.primaryColor,
                                cursor: "pointer",
                            }}
                            onClick={() => history.push(`/user/${post.user_upload.user.id}`)}
                        >
                            <BrAvatar theme={theme} user={post.user_upload.user} />
                            {/* TODO: Username links to their profile at /user/{user.id} */}
                            {post.user_upload.user?.name ?? "User"}
                        </span>
                    </Grid>
                    
                    <Grid item xs={3}>
                        {user.is_mentor && !approved ? (
                        <span style={{ textAlign: "right" }}>
                            {" "}
                            <IconButton
                            onClick={approvePostAsync}
                            style={{ width: "45px" }}
                            >
                            <CheckCircle />
                            </IconButton>{" "}
                        </span>
                        ) : null}
                        
                        {user.id === post.user_upload.user.id  ? (
                        <span style={{ textAlign: "right" }}>
                            {" "}
                            <IconButton
                            onClick={setPostEditing}
                            style={{ width: "45px" }}
                            >
                            <EditIcon/>
                            </IconButton>{" "}
                        </span>
                        ) : null}
                        
                        {/* If the post belongs to the user or the user is a mentor, show the delete icon */}
                        {user.id === post.user_upload.user.id || user.is_mentor ? (
                        <span style={{ textAlign: "right" }}>
                            {" "}
                            <IconButton
                            style={{ width: "45px" }}
                            >
                                {/*{//de;ete Icon }*/}
                                <AlertDialogSlide
                                action={removePostAsync}
                                />
                            </IconButton>{" "}
                        </span>
                        ) : null}
                    </Grid>
                </Grid>
                <div style={{ margin: "auto", textAlign: "center" }}>
                {editing ?
                <>
                <BootstrapInput onChange={ (event) => setMessage(event.target.value) } value={message} />
                <CheckCircle
                    style={{ cursor: "pointer" }}
                    onClick={() => updatePost()}
                />{" "}</> :  <Typography style={{textAlign:'left', margin:5}}> {message} </Typography> }
                
                </div>
                <CardMedia
                    style={{ width: "100%", objectFit: "fill",  margin: "auto" }}
                    component="img"
                    onClick={() => {
                        // window.location = post.user_upload.url; // Refactor this to open a new tab
                        window.open(`${post.user_upload.url}`, "_blank");
                    }}
                    image={`${post.user_upload.url}`}
                    title={`Image Uploaded by ${post.user_upload.user?.name ?? "User"}`}
                />
                <div
                    style={{
                        height: "50px",
                        whiteSpace: "nobreak",
                        marginTop: "10px",
                        textAlign: "center"
                    }}
                >
                {user.is_mentor && (
                    <>
                        <img
                            onClick={awardPost}
                            style={{
                                height: "24px",
                                width: "24px",
                                marginLeft: "3%",
                                cursor: "pointer"
                            }}
                            src="/icons/002-trophy.png"
                        />
                        <span style={{ marginLeft: "1%" }}>{awards.length}</span>
                    </>
                )}
                <img
                    onClick={likePost}
                    style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "3%",
                        cursor: "pointer"
                    }}
                    src="/icons/001-like.png"
                />
                <span style={{ marginLeft: "1%" }}>{likes.length}</span>
                <span style={{ marginLeft: "70%" }}> {comments.length} </span>
                <img
                    style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "2%",
                        cursor: "pointer"
                    }}
                    src="/icons/001-speech-bubble.png"
                />
                </div>
                <Divider style={{ width: "100%" }} />
                <Grid container>
                    {user.is_kid || user.children.length === 0 ? (
                        <>
                        <Grid item xs={1}>
                            <BrAvatar theme={theme} user={user} />
                        </Grid>
                        <Grid item xs={11}>
                            <Paper
                                elevation={0}
                                style={{
                                    width: "95%",
                                    height: "100%",
                                    textAlign: "center",
                                    marginTop: "5px",
                                    backgroundColor: "#f2f2f2"
                                }}
                        >
                        <TextField
                            style={{ width: "95%" }}
                            id="status"
                            variant={"filled"}
                            value={comment.content}
                            multiline
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={handleCommentChange}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                style: {
                                    backgroundColor: "#f2f2f2",
                                    padding: 0,
                                    after: {
                                        content: "none"
                                    },
                                    before: { content: "none" }
                                },
                                shrink: true,
                                disableUnderline: true
                            }}
                            inputProps={{
                                placeholder: "Write a comment",
                                style: {
                                    paddingTop: "13px",
                                    paddingBottom: "0px",
                                    backgroundColor: "#f2f2f2"
                                }
                            }}
                        />
                        </Paper>
                    </Grid>
                    </>
                ) : null}
                
                <Grid item xs={12} style={{ height: "10px" }}></Grid>
                
                {comments.length > 0
                    ? comments.map((comment) => (
                        <>
                        <Grid item xs={1}>
                            <BrAvatar theme={theme} user={comment.user} />
                        </Grid>
                        <Grid item xs={10}>
                            <Card
                                style={{
                                    backgroundColor: "#f2f2f2",
                                    marginTop: "5px",
                                    width: "95%"
                                }}
                            >
                                <b
                                    style={{ margin: "3px", textDecoration: "underline", color: theme.primaryColor, cursor: "pointer" }}
                                    onClick={() => {
                                        history.push(`/user/${comment.user.id}`);
                                    }}
                                > {comment.user?.name ?? "User"} </b>
                                <br />
                                <p style={{ margin: "3px" }}> {comment.content} </p>
                            </Card>
                        </Grid>
                        <Grid item xs={1}>
                            {user.is_mentor && !comment.approved ? (
                                <span style={{ textAlign: "right" }}>
                                    {" "}
                                    <CheckCircle
                                        style={{ cursor: "pointer" }}
                                        onClick={() => approveCommentAsync(comment)}
                                    />{" "}
                                </span>
                            ) : null}
                            {user?.id === comment.user?.id || user.is_mentor ? (
                                <span style={{ textAlign: "right" }}>
                                    {" "}
                                    <SimpleMenu comment={comment} />{" "}
                                </span>
                            ) : null}
                        </Grid>
                        </>
                    ))
                    : null}
                </Grid>
            </Card>
        </Grid>
        <Grid xs={4} />
        <Grid xs={4} />
    </>
    );
};