import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { IconButton } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const { REACT_APP_API_URL } = process.env;

export default function ProfileAccordions(props) {
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            minHeight: 500,
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: "#eb4c8a",
        },
        avatar: {
            marginLeft: "30%",
            marginTop: "40%",
            width: "20%",
            height: "20%",
        },
        pos: {
            marginBottom: 12,
        },
        rewardCard: {
            backgroundColor: "#efeaff",
        },
        heading: {
            fontSize: 16,
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        hobbyCard: {
            backgroundColor: "#dbf2d1",
        },
        videosCard: {
            backgroundColor: "#ffe5e5",
        },
        videoCardContainer: {
            margin: "4px",
            width: "80%",
            height: "75%",
        },
        playIcon: {
            color: "#eb4c8a",
            cursor: "pointer",
            position: "absolute",
            top: "20%",
            left: "25%",
            transform: "translate(-25%, -25%)",
        },
        imagesCard: {
            backgroundColor: "#d6f1fe",
        },
        images: {
            margin: "4px",
        },
        subCardReward: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#937cff",
        },
        subCardHobbies: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#4bbe0d",
        },
        hobbyIcon: {
            width: "80%",
            minHeight: "40px",
            marginTop: "20%",
            backgroundColor: "#dbf2d1",
        },
        hobbyLink: {
            marginBottom: "-10px",
            color: "#4bbe0d",
        },
        subCardVideos: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#fe787f",
        },
        subCardImages: {
            width: "50%",
            minHeight: "40px",
            marginTop: "20%",
            marginLeft: "10%",
            backgroundColor: "#2cbcf8",
        },
        xcReq: {
            marginLeft: "35%",
            width: "20%",
            height: "20%",
        }
    });
    
    const classes = useStyles();
    const [showHobbies, setShowHobbies] = useState(false);
    const [showVideos, setShowVideos] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [userHobbies, setUserHobbies] = useState([]);
    const [userImages, setUserImages] = useState([]);
    const [userVideos, setUserVideos] = useState([]);
    
    const toggleHobbies = () => {
        setShowHobbies(!showHobbies);
    };
    
    const toggleImages = () => {
        setShowImages(!showImages);
    };
    
    const toggleVideos = () => {
        setShowVideos(!showVideos);
    };
    
    const requestFriend = async () => {
        const result = await axios(`${REACT_APP_API_URL}/friend/${props.userId}`, {
            method: "POST",
        });
    };
    
    useEffect(async () => {
        const result = await axios(`${REACT_APP_API_URL}/hobbies/${props.userId}`);
        setUserHobbies(result.data);
    }, [showHobbies]);

    // useEffect(async () => {
    //     const result = await axios(
    //         `${REACT_APP_API_URL}/uploads/${props.userId}?type=image`
    // );
    // setUserImages(result.data);
    // }, [showImages]);
    
    // useEffect(async () => {
    //     const result = await axios(
    //         `${REACT_APP_API_URL}/uploads/${props.userId}?type=video`
    // );
    // setUserVideos(result.data);
    // setPlaying(result.data.map((e) => false));
    // }, [showVideos]);
    
    const [playing, setPlaying] = useState([]);
    const options = { year: "numeric", month: "long", day: "numeric" };
    
    const { user, awardCount } = props;
    console.log("🚀 ~ file: ProfileAccordions.jsx:174 ~ ProfileAccordions ~ user:", user);
    
    return (
        <>
            <Card className={classes.bullet + " " + classes.rewardCard}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Card className={classes.subCardReward}>
                            <img
                                style={{
                                    marginTop: "20%",
                                    marginLeft: "20%",
                                    maxHeight: "60%",
                                    maxWidth: "60%",
                                    filter:
                                    "invert(82%) sepia(67%) saturate(1459%) hue-rotate(334deg) brightness(101%) contrast(104%)",
                                }}
                                src="/icons/002-gift.png"
                            ></img>
                        </Card>
                    </Grid>
                    <Grid style={{ marginTop: "30px", fontWeight: "bold" }} item xs={9}>
                        Rewards
                    </Grid>
                    <Grid item xs={1}>
                    <Typography style={{ marginTop: 30, fontWeight: 24, fontSize: 24 }}>
                        {awardCount}
                    </Typography>
                    </Grid>
                </Grid>
            </Card>
            
            <Accordion
                className={classes.bullet + " " + classes.hobbyCard}
                expanded={showHobbies}
                onChange={toggleHobbies}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Grid item xs={2}>
                        <Card className={classes.subCardHobbies}>
                            <img
                                style={{
                                    marginTop: "20%",
                                    marginLeft: "20%",
                                    maxHeight: "60%",
                                    maxWidth: "60%",
                                    filter: "invert(82%) sepia(67%) saturate(1459%) hue-rotate(334deg) brightness(101%) contrast(104%)",
                                }}
                                src="/icons/003-extension.png"
                            />
                        </Card>
                    </Grid>
                    <Typography
                        className={classes.heading}
                        style={{ marginTop: "30px", fontWeight: "bold" }}
                        >
                        Hobbies
                    </Typography>
                </AccordionSummary>
                
                <AccordionDetails style={{ padding: 0, minHeight: "200px" }}>
                    <Card style={{ width: "100%" }}>
                        <Grid container spacing={1}>
                            {userHobbies.map((hobby, i) => {
                                let breakLine =
                                    i % 5 === 0 && i !== 0 ? (
                                    <>
                                        <Grid xs={1} /> <Grid xs={1} />{" "}
                                    </>
                                    ) : (
                                    ""
                                    );
                                let initialBreak = i === 0 ? <Grid xs={1} /> : "";
                                return (
                                    <>
                                        {initialBreak}
                                        {breakLine}
                                        <Grid item xs={2}>
                                            <Paper className={classes.hobbyIcon}>
                                                <img
                                                    style={{
                                                    marginTop: "20%",
                                                    marginLeft: "20%",
                                                    maxHeight: "60%",
                                                    maxWidth: "60%",
                                                    }}
                                                    src={`/${hobby.badge_url}`}
                                                ></img>
                                            </Paper>
                                            <Typography
                                                style={{ marginLeft: "20%", maxWidth: "20px" }}
                                            >
                                            {hobby.name}
                                            </Typography>
                                        </Grid>
                                    </>
                                );
                            })}
                            {/* <Grid item xs={11 - (userHobbies.length % 5) * 2} /> */}
                            <Grid item xs={11} />
                            <Grid item xs={10} />
                            <Grid item xs={2} style={{ height: "50px" }}>
                                {(user && user?.children?.length === 0) && (
                                    <Link to={"/hobbies"} className={classes.hobbyLink}>
                                    {" "}
                                        Edit hobbies{" "}
                                    </Link>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </AccordionDetails>
            </Accordion>
            
            <Accordion
                className={classes.bullet + " " + classes.videosCard}
                expanded={showVideos}
                onChange={toggleVideos}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Grid item xs={2}>
                        <Card className={classes.subCardVideos}>
                            <img
                            style={{
                                marginTop: "20%",
                                marginLeft: "20%",
                                maxHeight: "60%",
                                maxWidth: "60%",
                                filter:
                                "invert(82%) sepia(67%) saturate(1459%) hue-rotate(334deg) brightness(101%) contrast(104%)",
                            }}
                            src="/icons/001-video-camera.png"
                            ></img>
                        </Card>
                    </Grid>
                    <Typography
                        className={classes.heading}
                        style={{ marginTop: "30px", fontWeight: "bold" }}
                    >
                        My Videos
                    </Typography>
                </AccordionSummary>
                
                <AccordionDetails
                    style={{ padding: 0, minHeight: "200px", fontColor: "bold" }}
                >
                    <Card style={{ width: "100%" }}>
                        <Grid container>
                            {userVideos.map((video, index) => {
                            return (
                                <Grid item xs={6}>
                                <Card className={classes.videoCardContainer}>
                                    <ReactPlayer
                                    url={`${video.url}`}
                                    playing={playing[index]}
                                    width="100%"
                                    height="100%"
                                    />
                                </Card>
                                <IconButton
                                    onClick={() =>
                                    setPlaying(
                                        playing.map((value, i) =>
                                        i === index ? !value : false
                                        )
                                    )
                                    }
                                    className={classes.playIcon}
                                    style={{
                                    top: 200 + 250 * Math.floor(index / 2),
                                    left: index % 2 == 1 ? "70%" : "20%",
                                    }}
                                >
                                    {playing[index] ? (
                                    <PauseIcon fontSize="large" />
                                    ) : (
                                    <PlayArrowIcon fontSize="large" />
                                    )}
                                </IconButton>
                                <span>{video.filename}</span>
                                <br></br>
                                <span>
                                    {" "}
                                    {new Date(video.created_at).toLocaleDateString(
                                    "us",
                                    options
                                    )}{" "}
                                </span>
                                </Grid>
                            );
                            })}
                        </Grid>
                    </Card>
                </AccordionDetails>
            </Accordion>
            
            <Accordion
                className={classes.bullet + " " + classes.imagesCard}
                expanded={showImages}
                onChange={toggleImages}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Grid item xs={2}>
                    <Card className={classes.subCardImages}>
                        <img
                        style={{
                            marginTop: "20%",
                            marginLeft: "20%",
                            maxHeight: "60%",
                            maxWidth: "60%",
                            filter:
                            "invert(82%) sepia(67%) saturate(1459%) hue-rotate(334deg) brightness(101%) contrast(104%)",
                        }}
                        src="/icons/002-image.png"
                        ></img>
                    </Card>
                    </Grid>
                    <Typography
                    className={classes.heading}
                    style={{ marginTop: "30px", fontWeight: "bold" }}
                    >
                    My Images
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, minHeight: "200px" }}>
                    <Card style={{ width: "100%" }}>
                    <Grid container>
                        {userImages.map((image, index) => {
                        return (
                            <Grid item xs={6}>
                            <Card className={classes.images}>
                                <CardMedia
                                component="img"
                                alt=""
                                height="140"
                                className={classes.media}
                                onClick={() => {
                                    window.location = image.url;
                                }}
                                image={`${image.url}`}
                                title=""
                                />
                            </Card>
                            </Grid>
                        );
                        })}
                    </Grid>
                    </Card>
                </AccordionDetails>
            </Accordion>
        </>
    );
};