import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Input/Button";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;


export default function SelectChildUser(props) {
    const useStyles = makeStyles((theme) => ({
        button: {
            width: "120px",
            height: "25px",
            borderRadius: "12px",
            fontSize: "10px",
            margin: "5px",
        },
        root: {
            top: "0%",
            left: "0%",
            position: "absolute",
            background: "url(/flowers/colorful_balls.png)",
            minHeight: 300,
            backgroundPosition: "center 100px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "100%",
            width: "100%",
            height: "100%",
            marginTop: "-5%",
        },
        bullet: {
            minHeight: "100px",
            margin: "0 2px",
            transform: "scale(0.8)",
        },
        title: {
            fontSize: 32,
            fontFamily: "GoodUnicorn",
            color: "#bbb", //'#eb4c8a'
        },
        margin: {
            margin: theme.spacing(1),
        },
        modal: {
            position: "absolute",
            width: "30%",
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: "50%",
            left: "33%",
            zIndex: 1,
        },
        
        divider: {
            margin: 10,
        },
        selectedAvatar: {
            border: "3px solid #bc40fb", //#eb4c8a',
            borderRadius: "50%",
        },
        deselectedAvatar: {
            border: "3px solid #FFF",
        },
    }));
    
    const classes = useStyles();
    const history = useHistory();
    
    const { kids, proceedWithChild } = props;
    console.log("🚀 ~ file: SelectChildUser.jsx:75 ~ SelectChildUser ~ kids:", kids);
    
    const gridSpacing = 12 / (kids?.length ? kids.length : 1);
    
    const SelectChildModal = (props) => {
        const [selectedChild, setSelectedChild] = useState(kids ? kids[0] : {});
        return (
            <div key={selectedChild.id} className={classes.modal}>
                <Grid container>
                    {kids && kids.length ? kids.map((child) => {
                        return (
                            <Grid
                                key={child.id * Math.random()}
                                item
                                xs={gridSpacing}
                                style={{ textAlign: "center" }}
                            >
                                <img
                                    key={child.id * Math.random()}
                                    onClick={() => {
                                        setSelectedChild(child);
                                    }}
                                    src={`${REACT_APP_API_URL}/avatar/${child.gender === 'M' ? '/avatars/019-boy.png' : '/avatars/001-girl.png'}`}
                                    className={
                                        child.id == selectedChild.id
                                            ? classes.selectedAvatar
                                            : classes.deselectedAvatar
                                    }
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                        margin: "5px",
                                    }}
                                />
                                <div key={child.id * Math.random()}> {child.name} </div>
                            </Grid>
                        );
                    }) : []}
                    
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "25px",
                        }}
                    >
                        <Button
                            theme={{
                                secondaryColor: "#bc40fb",
                                primaryColor: "#E040FB",
                            }}
                            onClick={() => {
                                proceedWithChild(selectedChild);
                                history.push("/dashboard/parents");
                            }}
                        >
                            {" "}
                            Proceed{" "}
                        </Button>
                    </div>
                </Grid>
            </div>
        );
    };
    
    return (
        <>
            <div
                style={{
                    height: "100vh",
                    background: "url(images/background.jpg)",
                    backgroundSize: "cover",
                }}
            >
                <div
                    style={{
                        zIndex: 99,
                        width: "700px",
                        height: "150px",
                        position: "absolute",
                        top: "27%",
                        left: "41%",
                        backgroundSize: "contain",
                        backgroundImage: "url(images/xclogo.svg)",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {" "}
                </div>
                
                <div
                    style={{
                        zIndex: 99,
                        position: "absolute",
                        top: "42%",
                        left: "45%",
                    }}
                ></div>
                
                <Card
                    open={true}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <SelectChildModal />
                </Card>
            </div>
            <div className={classes.root}></div>
            <footer
                style={{
                    height: "70px",
                    backgroundColor: "#444",
                    marginTop: "-70px",
                    padding: "20px",
                }}
            >
                <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://xcbrgpal.com"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            XcBrgPal Website{" "}
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://xcbrgpal.com/terms"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            XcBrgPal Terms of Use
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://xcbrgpal.com/privacy"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            XcBrgPal Privacy Policy
                        </a>{" "}
                    </Grid>
                    <Grid item xs={2}>
                        {" "}
                        <a
                            href="https://xcbrgpal.com/kidsprivacy"
                            style={{ color: "white", fontWeight: 400 }}
                        >
                            {" "}
                            XcBrgPal Kids Privacy{" "}
                        </a>{" "}
                    </Grid>
                </Grid>
            </footer>
        </>
    );
};