import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Divider from "@material-ui/core/Divider";

import Button from "../Input/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
//import {loadStripe} from '@stripe/stripe-js';
import "./PaymentSignup.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripe = loadStripe('pk_test_51Hv64iLqwvgakaguR0b78uFXJRpgGdZ6QWTQy7tlkRMWKwDCy51EdMUcXghN3EyjvGHj28kSW8fGFH896VktqtMM00ztOaZW6N');

const subscriptions = [
  "price_1J115fLqwvgakaguTBZwZ3tf",
  "price_1J117TLqwvgakaguCdPg8r31",
  "price_1J118cLqwvgakaguyp8nV5EN",
];
const donations = [
  "price_1J9xcbClrH3f5EOWbitAlno1",
  "price_1J9xcbClrH3f5EOWccu2Sjj8",
  "price_1J9xcbClrH3f5EOWVNNhUExL",
];

const { REACT_APP_API_URL } = process.env;

export default function PaymentSignUp(props) {
  const { user, theme, open } = props;

  const useStyles = makeStyles((theme2) => ({
    button: {
      width: "80px",
      height: "25px",
      borderRadius: "12px",
      fontSize: "10px",
      margin: "5px",
    },
    card: {
      margin: "15px",
      padding: "15px",
      height: "128px",
      width: "128px",
    },
    selectedCard: {
      margin: "15px",
      padding: "15px",
      height: "128px",
      width: "128px",
      border: `2px solid ${theme.primaryColor}`,
    },
    root: {
      minHeight: 500,
      minWidth: 258,
    },
    bullet: {
      minHeight: "100px",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 32,
      fontFamily: "GoodUnicorn",
      color: theme.primaryColor,
    },
    margin: {
      margin: theme2.spacing(1),
    },
    modal: {
      backgroundColor: theme2.palette.background.paper,
      border: "40px solid transparent",
      borderTop: "none",
      borderBottom: "none",
      //      borderImage: 'url(flowers/border.png) 27 50px 30px round space',
      boxShadow: theme2.shadows[5],
      padding: theme2.spacing(2, 4, 3),
      position: "absolute",
      width: 500,
      height: 400,
      top: "30%",
      left: "30%",
      overflow: "scroll",
    },
    selectedAvatar: {
      border: `3px solid ${theme.primaryColor}`, //#eb4c8a',
      borderRadius: "50%",
    },
    deselectedAvatar: {
      border: "3px solid #FFF",
    },
    divider: {
      margin: 10,
    },
    selectedDonation: {
      color: theme.primaryColor,
    },
  }));

  const StripePayment = (props) => {
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(null);
    const { open, selected, onClose } = props;

    const handleDonate = async (event) => {
      // Block native form submission.
      event.preventDefault();

      const { stripe, elements } = props;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      const result = await stripe.createToken(cardElement);

      if (result.error) {
      } else {
      }

      setProcessing(true);
      let amount = "";
      switch (donation) {
        case 1:
          amount = 500;
          break;
        case 2:
          amount = 1000;
          break;
        case 3:
          amount = 2000;
          break;
        case 4:
          amount = customDonation * 100;
          break;
      }
      const result2 = await fetch(`${REACT_APP_API_URL}/stripe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: result.token.id,
          amount,
        }),
      }).then((r) => r.json());

      if (result2.error) {
        setError(`Payment failed ${result2.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        onClose();
      }
    };

    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      const { stripe, elements } = props;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      setProcessing(true);
      const response = await fetch(
        `${REACT_APP_API_URL}/create-paypal-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: subscriptions[selected],
          }),
        }
      ).then((r) => r.json());

      console.log(response);
      /*
      let  payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'jenny rosen',
          }
        }
      });*/

      if (response.error) {
        setError(`Payment failed ${response.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        setStage(3);
      }
    };

    const stripe = { props };

    const cardStyle = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    const handleChange = async (event) => {
      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      console.log(event);
      setDisabled(event.empty);
      setError(event.error ? event.error.message : "");
    };

    return (
      <form
        id="payment-form"
        className="payment-form"
        onSubmit={donation > 0 ? handleDonate : handleSubmit}
      >
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
        <button disabled={processing || disabled || succeeded} id="submit">
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded!
        </p>
      </form>
    );
  };

  const InjectedCheckoutForm = (props) => {
    const { stripe, selected, onClose } = props;
    return (
      <Elements stripe={stripe}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <StripePayment
              onClose={onClose}
              elements={elements}
              stripe={stripe}
              selected={selected}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  };

  const [selected, setSelected] = useState(-1);
  const [donation, setDonation] = useState(-1);
  const [customDonation, setCustomDonation] = useState("0.00");

  const handleDonation = (index) => {
    setDonation(index);
  };
  const handleCustomDonation = (e) => {
    setCustomDonation(e.target.value);
  };

  const [stage, setStage] = useState(1);
  useEffect(() => {
    setStage(1);
    setDonation(-1);
  }, [open]);

  const classes = useStyles();

  let modalBody = "";
  console.log(subscriptions[selected]);

  switch (stage) {
    case 1:
      modalBody = (
        <div className={classes.modal}>
          <Grid container>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Typography className={classes.title} gutterBottom>
                Choose a Subscription Plan
              </Typography>
            </div>
            <Divider />

            <Grid item xs={4}>
              <Card
                onClick={() => setSelected(0)}
                className={selected === 0 ? classes.selectedCard : classes.card}
              >
                <Typography style={{ fontSize: "22px", textAlign: "center" }}>
                  {" "}
                  Monthly{" "}
                </Typography>
                <Divider />
                <Typography
                  style={{
                    fontSize: "22px",
                    textAlign: "center",
                    color: theme.primaryColor,
                  }}
                >
                  {" "}
                  $11.99{" "}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                onClick={() => setSelected(1)}
                className={selected === 1 ? classes.selectedCard : classes.card}
              >
                <Typography style={{ textAlign: "center", fontSize: "22px" }}>
                  {" "}
                  Semi Annual{" "}
                </Typography>
                <Divider />
                <Typography
                  style={{
                    fontSize: "22px",
                    textAlign: "center",
                    color: theme.primaryColor,
                  }}
                >
                  {" "}
                  $59.99{" "}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                onClick={() => setSelected(2)}
                className={selected === 2 ? classes.selectedCard : classes.card}
              >
                <Typography style={{ textAlign: "center", fontSize: "22px" }}>
                  {" "}
                  Annually{" "}
                </Typography>
                <Divider />
                <Typography
                  style={{
                    fontSize: "22px",
                    textAlign: "center",
                    color: theme.primaryColor,
                  }}
                >
                  {" "}
                  $99.99{" "}
                </Typography>
              </Card>
            </Grid>

            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                theme={theme}
                onClick={async () => {
                  setStage(2); /*
                  const response = await fetch(`${REACT_APP_API_URL}/create-paypal-subscription`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      priceId: subscriptions[selected]
                    })
                  }).then(r => r.json()); */
                }}
              >
                {" "}
                Next - Payment{" "}
              </Button>
            </div>
          </Grid>
        </div>
      );
      break;
    case 2:
      modalBody = (
        <div className={classes.modal}>
          <Button> Pay with M-Pesa </Button>

          <PayPalScriptProvider
            options={{
              "client-id":
                "Acs8Mas7BjmPDvYCsdQ54ddZZuJFEXSQ-9NyAXG6q-rk_Yj6PargGI2W51ELVLrCp9DBZs_OMZUHNYly",
              vault: true,
              intent: "subscription",
            }}
          >
            <PayPalButtons
              style={{
                shape: "pill",
                color: "gold",
                layout: "vertical",
                label: "subscribe",
              }}
              createSubscription={function (data, actions) {
                console.log(actions);
                return actions.subscription.create({
                  /* Creates the subscription */
                  plan_id: "P-2WB62885HM767412AMELTARY",
                });
              }}
              onApprove={function (data, actions) {
                alert(data.subscriptionID); // You can add optional success message for the subscriber here
              }}
            />
          </PayPalScriptProvider>
        </div>
      );
      break;

    case 3:
      modalBody = (
        <div className={classes.modal}>
          <Grid container>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Typography className={classes.title} gutterBottom>
                Donation (Optional)
              </Typography>
              <Grid item xs={12}>
                <Button
                  theme={donation === 1 ? theme : {}}
                  onClick={() => handleDonation(1)}
                >
                  {" "}
                  $5{" "}
                </Button>
                <Button
                  theme={donation === 2 ? theme : {}}
                  onClick={() => handleDonation(2)}
                >
                  {" "}
                  $10{" "}
                </Button>
                <Button
                  theme={donation === 3 ? theme : {}}
                  onClick={() => handleDonation(3)}
                >
                  {" "}
                  $20{" "}
                </Button>
                <Button
                  theme={donation === 4 ? theme : {}}
                  onClick={() => handleDonation(4)}
                >
                  {" "}
                  Other{" "}
                </Button>
                {donation === 4 && (
                  <Fragment>
                    <input
                      type="number"
                      min="0.01"
                      max="2500"
                      onChange={handleCustomDonation}
                      value={customDonation}
                    />{" "}
                    $
                  </Fragment>
                )}
              </Grid>
            </div>
            <Divider />
            {donation > 0 && (
              <Fragment>
                <Typography
                  style={{ width: "100%", textAlign: "center" }}
                  gutterBottom
                >
                  Pay With Credit Card
                </Typography>
                <InjectedCheckoutForm
                  onClose={props.handleClose}
                  stripe={{} /*stripe*/}
                  selected={selected}
                  style={{ width: "100%", height: "100%" }}
                />
              </Fragment>
            )}
          </Grid>
        </div>
      );
      break;

    default:
      modalBody = "hello world";
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalBody}
    </Modal>
  );
}
